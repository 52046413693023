import { fetchUtils } from "react-admin";

export const aircallService = {
  createCallsCampaign: async (
    username: string,
    userId: any,
    phoneNumbers: string[]
  ) => {
    const url = `${process.env.REACT_APP_SERVER_URL}/api/users/${userId}/callsCampaign`;
    const options = {
      method: "POST",
      body: JSON.stringify({ username, phoneNumbers }),
      headers: new Headers({
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("credentials") || "",
      }),
    };
    return await fetchUtils.fetchJson(url, options);
  },

  dialNumber: async (username: string, userId: any, phoneNumber: string) => {
    const url = `${process.env.REACT_APP_SERVER_URL}/api/users/${userId}/dial`;
    const options = {
      method: "POST",
      body: JSON.stringify({ username, phoneNumber }),
      headers: new Headers({
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("credentials") || "",
      }),
    };
    const response = await fetchUtils.fetchJson(url, options);
    return response;
  },
};
