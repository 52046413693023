import * as React from "react";
import { useEffect, useState } from "react";

import { Step, StepLabel, Stepper } from "@material-ui/core";
import qs from "qs";
import {
  Button,
  Create,
  CreateProps,
  LoadingIndicator,
  SaveButton,
  SimpleForm,
  Toolbar,
  useNotify,
} from "react-admin";
import { useHistory, useLocation } from "react-router-dom";
import AdditionalContactForm from "../Components/form/AdditionalContactForm";
import UserCreateForm from "../Components/form/UserCreateForm";

export const UserCreate = (props: CreateProps): React.ReactElement => {
  const notify = useNotify();
  const parsedLocation = qs.parse(useLocation().search.slice(1));
  const history = useHistory();
  const [activeStep, setActiveStep] = useState<number>(0);
  const [isValid, setIsValid] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isAdditionalContact, setIsAdditionalContact] = useState(false);
  const [isLawyerUser, setIsLawyerUser] = useState(false);
  const [isInsolvencyAdministrator, setIsInsolvencyAdministrator] =
    useState(false);

  const steps = [
    { label: "Basic Information" },
    { label: "Operational Preferences" },
    { label: "Accounting Preferences" },
    { label: "Additional Information" },
  ];

  const handleNext = () => {
    if (isValid) {
      setActiveStep((prev) => prev + 1);
    } else {
      notify("Please fill in all required fields correctly", "error");
    }
  };

  const handleValidationChange = (valid: boolean) => {
    setIsValid(valid);
  };

  useEffect(() => {
    if (parsedLocation.userType === "AdditionalContact") {
      setIsAdditionalContact(true);
      setActiveStep(steps.length - 1);
    } else if (parsedLocation.userType === "Lawyer") {
      setIsAdditionalContact(true);
      setIsLawyerUser(true);
      setActiveStep(steps.length - 1);
    } else if (parsedLocation.userType === "InsolvencyAdministrator") {
      setIsAdditionalContact(true);
      setIsInsolvencyAdministrator(true);
      setActiveStep(steps.length - 1);
    }
    setIsLoading(false);
  }, [parsedLocation.userType, steps.length]);

  if (isLoading) {
    return <LoadingIndicator />;
  }

  return (
    <Create
      {...props}
      onSuccess={({ data }) => {
        notify("User created. Add address now.", "success");
        history.push(
          "/Address/create?userId=" +
            data.id +
            "&isWizard=true" +
            (parsedLocation.userType
              ? "&userType=" + parsedLocation.userType
              : "")
        );
        return false;
      }}
    >
      <SimpleForm
        toolbar={
          <Toolbar
            style={{
              background: "white",
              borderTop: "1px solid rgba(0,0,0,0.1)",
              width: "97%",
              maxWidth: "100%",
              display: "flex",
              justifyContent: activeStep > 0 ? "space-between" : "flex-end",
              alignItems: "center",
              marginTop: "2rem",
            }}
          >
            {activeStep > 0 && (
              <Button
                disabled={isAdditionalContact}
                label="Back"
                onClick={() => setActiveStep((prev) => prev - 1)}
                variant="outlined"
                color="secondary"
              />
            )}
            {activeStep < steps.length - 1 && (
              <Button
                label="Next"
                onClick={handleNext}
                variant="contained"
                color="primary"
              />
            )}
            {activeStep === steps.length - 1 && <SaveButton label="Save" />}
          </Toolbar>
        }
      >
        {isAdditionalContact ? (
          <AdditionalContactForm
            onValidationChange={handleValidationChange}
            isLaywerUser={isLawyerUser}
            isInsolvencyAdministrator={isInsolvencyAdministrator}
          />
        ) : (
          <>
            <Stepper activeStep={activeStep} style={{ width: "95%" }}>
              {steps.map(({ label }) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
            <UserCreateForm
              activeStep={activeStep}
              onValidationChange={handleValidationChange}
            />
          </>
        )}
      </SimpleForm>
    </Create>
  );
};
