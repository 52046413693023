import { useState } from "react";

import { TextInput } from "react-admin";
import { useFormState } from "react-final-form";

const UsernameInput = (props: any) => {
  const [manualMode, setManualMode] = useState(false);
  const { values } = useFormState();

  if (!manualMode) {
    if (values.email) {
      values.username = values.email;
    } else {
      values.username = values.phone;
    }
  }

  return (
    <TextInput
      label="Username (generated)"
      source="username"
      InputProps={{
        className: !manualMode ? "Mui-disabled" : "",
      }}
      onFocus={() => setManualMode(true)}
      onBlur={(event) => {
        if (!event?.target?.value) {
          setManualMode(false);
        }
      }}
      {...props}
    />
  );
};

export default UsernameInput;
