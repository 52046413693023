import { useCallback, useEffect, useState } from "react";
import {
  Record,
  useDataProvider,
  useGetOne,
  useNotify,
  useRecordContext,
  useRefresh,
} from "react-admin";
import { useClaimShowLogic } from "./useClaimShowLogic";
import { useLawyerStatus } from "./useLawyerStatus";
import { useTitle } from "./useTitle";

export const useClaim = (claimId) => {
  const record: Record = useRecordContext();
  const notify = useNotify();
  const refresh = useRefresh();
  const dataProvider = useDataProvider();

  const { setHasLawyers, setHasInsolvencyAdministrators } =
    useLawyerStatus(claimId);
  const [userCountry, setUserCountry] = useState();
  const { absoluteTotal } = useClaimShowLogic(claimId);

  const { data: creditorData } = useGetOne("User", record?.creditor.id || 0, {
    enabled: !!record?.creditor.id,
  });

  const { data: debtorData } = useGetOne("User", record?.debtor.id || 0, {
    enabled: !!record?.debtor.id,
  });

  useEffect(() => {
    const checkAssociatedEntities = async () => {
      if (record?.id) {
        try {
          const { data: lawyers } = await dataProvider.getList("User", {
            filter: {
              claimsAsContact: { some: { id: { equals: record.id } } },
              userType: "Lawyer",
            },
            pagination: { page: 1, perPage: 1 },
            sort: { field: "id", order: "ASC" },
          });

          setHasLawyers(lawyers.length > 0);
          const { data: administrators } = await dataProvider.getList("User", {
            filter: {
              claimsAsContact: { some: { id: { equals: record.id } } },
              userType: "InsolvencyAdministrator",
            },
            pagination: { page: 1, perPage: 1 },
            sort: { field: "id", order: "ASC" },
          });
          setHasInsolvencyAdministrators(administrators.length > 0);
        } catch (error) {
          console.error("Error checking associated entities:", error);
        }
      }
    };

    checkAssociatedEntities();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataProvider, record?.id]);

  const updateClaimStatus = useCallback(
    async (newStatus: string) => {
      if (
        ["Cancelled", "Paid", "Closed"].includes(newStatus) &&
        !window.confirm("Are you sure?")
      ) {
        return;
      }

      const newRecord = { ...record, status: newStatus };
      await dataProvider.update("Claim", {
        id: record.id,
        data: newRecord,
        previousData: record,
      });
      refresh();
    },
    [dataProvider, record, refresh]
  );

  useTitle(
    record
      ? `${record.reference} ${
          !!creditorData?.businessName
            ? ` ${creditorData.businessName.slice(0, 3).toUpperCase()} `
            : ""
        } ./. ${
          debtorData?.businessName ||
          debtorData?.contactName ||
          debtorData?.name ||
          ""
        }`
      : ""
  );

  if (!claimId) {
    return {
      record: null,
      creditorData: null,
      debtorData: null,
      userCountry: null,
      setUserCountry: () => {},
      absoluteTotal: 0,
      updateClaimStatus: () => {},
      notify,
      refresh,
    };
  }

  return {
    record,
    creditorData,
    debtorData,
    userCountry,
    setUserCountry,
    absoluteTotal,
    updateClaimStatus,
    notify,
    refresh,
  };
};
