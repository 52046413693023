import {
  Card,
  Chip,
  CircularProgress,
  Divider,
  Grid,
  ListItem,
  ListItemText,
  List as MuiList,
  Tab,
  Tabs,
  Tooltip,
  Typography,
} from "@material-ui/core";
import * as icons from "@material-ui/icons";
import jsonExport from "jsonexport/dist";
import * as React from "react";
import { useCallback, useEffect, useState } from "react";
import {
  Tab as AdminTab,
  ArrayField,
  BooleanField,
  Button,
  Datagrid,
  DateField,
  downloadCSV,
  Filter,
  FunctionField,
  List,
  NullableBooleanInput,
  NumberField,
  Pagination,
  ReferenceField,
  RichTextField,
  SelectInput,
  Show,
  ShowProps,
  SimpleForm,
  TextField,
  useDataProvider,
  useGetIdentity,
  useListContext,
  useNotify,
  useRecordContext,
  useRefresh,
} from "react-admin";
import ReactCountryFlag from "react-country-flag";
import {
  Address,
  ADDRESS_COUNTRY_READABLE,
  ADDRESS_COUNTRY_SHORT,
  normalizePhoneNumber,
} from "../api/address/Address";
import { EnumAddressCountry } from "../api/address/EnumAddressCountry";
import { Claim } from "../api/claim/Claim";
import { User } from "../api/user/User";
import { ClaimListItems } from "../claim/ClaimList";
import { NonInput } from "../Components/NonInputComponent";
import { TabbedShowLayoutStyled } from "../Components/tabs/claimShow/TabbedShowLayoutStyled";
import { RESEARCH_MANAGERS } from "../config/AppConfig";
import { useTitle } from "../context/hook/useTitle";
import { usePhone } from "../context/PhoneContext";
import { INTEGRATION_TITLE_FIELD } from "../integration/IntegrationTitle";
import { theme } from "../theme/theme";
import { aircallService } from "../util/AirCallCalls";
import { searchMapByKey } from "../util/MapUtils";

const ClaimExportButton = () => {
  const { data } = useListContext();
  const dataProvider = useDataProvider();
  const [isLoading, setIsLoading] = useState(true);
  const [listToExport, setListToExport] = useState<any[]>([]);

  useEffect(() => {
    const claims: Claim[] = Object.values(data) as Claim[];
    const fetchData = async () => {
      for (const claim of claims) {
        const result: Claim | any = { ...claim };
        if (claim.debtor?.id) {
          await dataProvider
            .getList("Address", {
              pagination: { page: 1, perPage: 1 },
              sort: { field: "createdAt", order: "DESC" },
              filter: { user: { id: claim.debtor?.id } },
            })
            .then(async (addressReponse) => {
              delete result.activities;
              delete result.activitiesIds;
              delete result.creditorFeedbackRequired;
              delete result.fileUrls;
              delete result.id;
              delete result.paymentPlans;
              delete result.paymentsIds;
              delete result.payments;
              delete result.updatedAt;
              delete result.createdAt;

              try {
                result.originalAmountDue = parseFloat(
                  (result.originalAmountDue as number)?.toFixed(2)
                ).toLocaleString("de", {
                  minimumFractionDigits: 2,
                });
                result.totalPaid = parseFloat(
                  (result.totalPaid as number)?.toFixed(2)
                ).toLocaleString("de", {
                  minimumFractionDigits: 2,
                });
                result.totalPending = parseFloat(
                  (result.totalPending as number)?.toFixed(2)
                ).toLocaleString("de", {
                  minimumFractionDigits: 2,
                });

                result.invoiceCreatedAt = new Date(result.invoiceCreatedAt)
                  ?.toISOString()
                  ?.split("T")[0];
                result.dueDate = new Date(result.dueDate)
                  ?.toISOString()
                  ?.split("T")[0];
              } catch (e) {
                console.log("error in export line: " + result.id, e);
              }
              result.street = addressReponse.data[0]?.addressLine1;
              result.country = addressReponse.data[0]?.country;
              result.postalcode = addressReponse.data[0]?.postalcode;
              result.town = addressReponse.data[0]?.town;

              await dataProvider
                .getList("User", {
                  pagination: { page: 1, perPage: 1 },
                  sort: { field: "createdAt", order: "DESC" },
                  filter: { id: { equals: claim.debtor?.id || 0 } },
                })
                .then((userResponse) => {
                  result.debtorBusinessName =
                    userResponse.data[0]?.businessName;
                  result.debtorContactName = userResponse.data[0]?.contactName;
                  result.debtorPhone = userResponse.data[0]?.phone;
                  result.debtorEmail = userResponse.data[0]?.email;
                  result.debtorVatId = userResponse.data[0]?.vatId;

                  // final cleanups
                  delete result.creditor.id;
                  delete result.creditor;
                  delete result.debtor.id;
                  delete result.debtor;

                  listToExport.push(result);
                });
            });
        }
      }
    };

    fetchData().then(() => {
      setIsLoading(false);
      setListToExport(listToExport);
    });
  }, [data, dataProvider, isLoading, listToExport]);

  if (isLoading) {
    return <CircularProgress size={24} />;
  }
  return (
    <Button
      variant="outlined"
      size="medium"
      onClick={() => {
        jsonExport(listToExport, { rowDelimiter: ";" }, (err, csv) => {
          // fix encoding
          const BOM = "\uFEFF";
          downloadCSV(`${BOM} ${csv}`, "Claims" + new Date().toISOString());
        });
      }}
      label="Download Export"
    />
  );
};

const ClaimFilter = React.memo((props: any) => {
  return (
    <Filter {...props}>
      <SelectInput
        alwaysOn
        style={{ width: "50%", minWidth: 260 }}
        source="status"
        label="Status"
        choices={[
          { label: "Open", value: "Open" },
          { label: "Paused", value: "Paused" },
          { label: "Reminder Level", value: "ReminderLevel" },
          { label: "Encashment Level", value: "EncashmentLevel" },
          { label: "Judicial Level", value: "JudicialLevel" },
          { label: "Paid", value: "Paid" },
          { label: "Closed", value: "Closed" },
          { label: "Cancelled", value: "Cancelled" },
        ]}
        optionText="label"
        optionValue="value"
      />
      <NullableBooleanInput
        source="contacts"
        label="Has Lawyer"
        alwaysOn
        value={props.hasLawyer}
        onChange={(event) => {
          props.setHasLawyer(event?.target?.value);
        }}
      />
    </Filter>
  );
});

export const UserBusinessName = () => {
  const record = useRecordContext();
  const notify = useNotify();

  return (
    <div style={{ display: "inline-block" }}>
      <Typography
        className="MuiTypography-root MuiTypography-body2"
        component="span"
        variant="body2"
      >
        {record?.businessName}
      </Typography>
      {record?.businessName && (
        <span>
          <icons.FileCopyOutlined
            style={{
              height: 15,
              cursor: "copy",
              color: "var(--black10)",
            }}
            onClick={() => {
              navigator.clipboard.writeText(record?.businessName);
              notify("Copied to clipboard", "info", null, false);
            }}
          />
        </span>
      )}
    </div>
  );
};

export const UserEmail = () => {
  const record = useRecordContext();
  const notify = useNotify();

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        padding: "0 10px",
        justifyContent: "space-evenly",
      }}
    >
      <Typography
        className="MuiTypography-root MuiTypography-body2"
        component="span"
        variant="body2"
      >
        {record?.email}
      </Typography>
      {record?.email && (
        <span style={{ display: "flex", alignItems: "center" }}>
          <icons.FileCopyOutlined
            style={{
              height: 15,
              cursor: "copy",
              color: "var(--black10)",
            }}
            onClick={() => {
              navigator.clipboard.writeText(record?.email);
              notify("Copied to clipboard", "info", null, false);
            }}
          />
          {(!record.emailVerificationStatus ||
            record.emailVerificationStatus === "UNKNOWN") && (
            <icons.Help
              style={{
                height: 15,
                color: theme.palette.warning.dark,
              }}
            />
          )}
          {record.emailVerificationStatus === "VALID" && (
            <icons.VerifiedUser
              style={{
                height: 15,
                color: theme.palette.success.main,
              }}
            />
          )}
          {record.emailVerificationStatus === "INVALID" && (
            <icons.RemoveCircle
              style={{
                height: 15,
                color: theme.palette.error.main,
              }}
            />
          )}
        </span>
      )}
    </div>
  );
};

export const PhoneNumber = (props: {
  country?: EnumAddressCountry;
  phoneRef?: any;
}): any => {
  const record = useRecordContext();
  const { airCallTab } = usePhone();
  const [normalizedNumber, setNormalizedNumber] = useState<string>(
    record?.phone
      ? normalizePhoneNumber(record?.phone, props.country, true)
      : ""
  );
  const dataProvider = useDataProvider();
  const notify = useNotify();

  const { identity } = useGetIdentity();
  const handleCall = () => {
    aircallService
      .dialNumber(identity?.username, identity?.id, normalizedNumber)
      .then((result) => {
        if (result?.json) {
          notify("Dialing Call", "success");
          if (airCallTab && !airCallTab.closed) {
            // The tab is open, switch to it
            airCallTab.focus();
          } else {
            /*
            // The tab is closed or hasn't been opened, open it
            setAirCallTab(window.open("https://phone.aircall.io/", "_blank"));
            */
          }
        } else {
          notify("Could not dial :( Wrong number? Busy?", "error");
        }
      });
  };

  useEffect(() => {
    const fetchData = async () => {
      if (!props.country && !!record?.address?.id) {
        const address = await dataProvider.getOne("Address", {
          id: record?.address?.id,
        });
        if (address) {
          setNormalizedNumber(
            normalizePhoneNumber(
              record?.phone,
              address?.data?.country as EnumAddressCountry,
              true
            )
          );
        }
      }
    };
    fetchData();
  }, [dataProvider, props.country, record?.address?.id, record?.phone]);

  useEffect(() => {
    if (props?.country) {
      setNormalizedNumber(
        normalizePhoneNumber(
          record?.phone,
          props?.country as EnumAddressCountry,
          true
        )
      );
    }
  }, [props?.country, record?.phone]);

  if (!normalizedNumber) {
    return null;
  }

  return (
    <div>
      <Typography
        className="MuiTypography-root MuiTypography-body2"
        component="span"
        variant="body2"
        style={{ cursor: "pointer" }}
        onClick={() => {
          handleCall();
        }}
      >
        <icons.Phone
          style={{
            width: "auto",
            height: 12,
            verticalAlign: "middle",
            marginRight: 8,
            marginBottom: 2,
          }}
        />
        {normalizedNumber}
      </Typography>
      {normalizedNumber && (
        <span style={{ verticalAlign: "middle" }}>
          <icons.FileCopyOutlined
            style={{
              height: 14,
              cursor: "copy",
              color: "var(--black10)",
            }}
            onClick={() => {
              navigator.clipboard.writeText(normalizedNumber);
              notify("Copied to clipboard", "info", null, false);
            }}
          />
          {(!record.phoneVerificationStatus ||
            record.phoneVerificationStatus === "UNKNOWN") && (
            <icons.Help
              style={{
                height: 14,
                color: theme.palette.warning.dark,
              }}
            />
          )}
          {record.phoneVerificationStatus === "VALID" && (
            <icons.VerifiedUser
              style={{
                height: 14,
                color: theme.palette.success.main,
              }}
            />
          )}
          {record.phoneVerificationStatus === "INVALID" && (
            <icons.RemoveCircle
              style={{
                height: 14,
                color: theme.palette.error.main,
              }}
            />
          )}
        </span>
      )}
    </div>
  );
};

const ClaimsAsCreditor = React.memo(() => {
  const [showClaimsAsCreditorExport, setShowClaimsAsCreditorExport] =
    useState(false);
  const record = useRecordContext();

  const handleExportButtonClick = useCallback(() => {
    setShowClaimsAsCreditorExport(true);
  }, []);
  const [hasLawyer, setHasLawyer] = useState("");

  if (
    (!record?.userType?.includes("Default") && !!record?.userType) ||
    !record?.roles?.includes("creditor")
  ) {
    return null;
  }

  return (
    <>
      <ClaimActions />
      <List
        resource="Claim"
        basePath="/Claim"
        title="Claims as creditor"
        exporter={false}
        pagination={<Pagination rowsPerPageOptions={[25, 50, 100, 250]} />}
        perPage={25}
        filter={{
          creditor: {
            id: record.id,
          },
          ...(hasLawyer === "true"
            ? {
                contacts: {
                  some: {
                    userType: "Lawyer",
                  },
                },
              }
            : hasLawyer === "false"
            ? {
                contacts: {
                  none: {
                    userType: "Lawyer",
                  },
                },
              }
            : { contacts: {} }),
        }}
        filterDefaultValues={{
          creditor: {
            id: record.id,
          },
        }}
        filters={
          <ClaimFilter hasLawyer={hasLawyer} setHasLawyer={setHasLawyer} />
        }
        sort={{ field: "createdAt", order: "DESC" }}
        actions={false}
      >
        <>
          <ClaimListItems extended hiddenFields={["creditor"]} />
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <ClaimTotalsRow />
            </Grid>
            <Grid
              item
              xs={6}
              alignItems={"center"}
              justifyContent={"flex-end"}
              style={{ display: "flex", paddingRight: "1.5rem" }}
            >
              {!showClaimsAsCreditorExport && (
                <Button
                  variant="outlined"
                  size="medium"
                  onClick={handleExportButtonClick}
                  label="Prepare Claim Export"
                />
              )}

              {showClaimsAsCreditorExport && <ClaimExportButton />}
            </Grid>
          </Grid>
        </>
      </List>
    </>
  );
});

const ClaimActions = () => {
  const notify = useNotify();
  const record = useRecordContext();
  const refresh = useRefresh();
  const { identity } = useGetIdentity();

  if (!RESEARCH_MANAGERS.includes(identity?.username.toString())) {
    return null;
  }

  const startNewClaims = () => {
    const authHeader = {
      headers: { Authorization: localStorage.getItem("credentials") || "" },
    };

    fetch(
      process.env.REACT_APP_SERVER_URL +
        "/api/claims/cron/execute?where[creditor][id]=" +
        record.id,
      {
        ...authHeader,
      }
    )
      .then((response) => response.text())
      .then((response) => {
        notify(response, "info");
        refresh(true);
      });
  };

  return (
    <Button
      variant="contained"
      size="medium"
      onClick={startNewClaims}
      label={"Start new claims"}
      style={{
        position: "absolute",
        right: 0,
        zIndex: 9999,
        top: "1rem",
      }}
    />
  );
};

const ClaimTotalsRow = () => {
  const { data } = useListContext();
  const claims: Claim[] = (Object.values(data) as Claim[]) || [];

  if (!claims || !claims?.length) {
    return null;
  }

  const numberFormat = Intl.NumberFormat("de-DE", {
    style: "currency",
    currency: "EUR",
  });

  const totalPaidSum = claims.reduce(function (a, b: any) {
    return a + (b.totalPaid > 0 ? b.totalPaid : 0);
  }, 0);
  const totalPendingSum = claims.reduce(function (a, b: any) {
    return a + (b.totalPending > 0 ? b.totalPending : 0);
  }, 0);

  if (!totalPaidSum && !totalPendingSum) {
    return null;
  }

  return (
    <div style={{ padding: "1rem" }}>
      <Typography variant={"body2"}>
        Total paid:{" "}
        <Typography
          style={{ fontSize: "inherit", fontWeight: "bold", display: "inline" }}
        >
          {numberFormat.format(totalPaidSum)}
        </Typography>
      </Typography>
      <Typography variant={"body2"}>
        Total pending:{" "}
        <Typography
          style={{ fontSize: "inherit", fontWeight: "bold", display: "inline" }}
        >
          {numberFormat.format(totalPendingSum)}
        </Typography>
      </Typography>
    </div>
  );
};

export const UserShow = (props: ShowProps): React.ReactElement => {
  const handleKeyPress = useCallback(
    (event) => {
      if (event.target === document.body && event.key === "e") {
        window.open("/#/User/" + props.id + "/", "_self");
      }
    },
    [props.id]
  );

  useEffect(() => {
    document.addEventListener("keydown", handleKeyPress);

    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, [handleKeyPress]);

  return (
    <Show {...props}>
      <UserShowLayout />
    </Show>
  );
};

export const RolesField = ({ record }) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        flexWrap: "wrap",
        gap: "5px",
      }}
    >
      {record.roles.map((role, index) => (
        <Chip
          key={index}
          label={role}
          size="small"
          variant="outlined"
          style={{
            borderColor: theme.palette.grey[200],
            background: theme.palette.common.white,
            borderRadius: 5,
            textTransform: "capitalize",
            fontSize: "0.75rem",
            fontWeight: "bold",
            color:
              role === "creditor"
                ? theme.palette.success.dark
                : role === "debtor"
                ? theme.palette.error.dark
                : theme.palette.secondary.dark,
          }}
        />
      ))}
    </div>
  );
};

const UserShowLayout = () => {
  const notify = useNotify();
  const record: User = useRecordContext();
  useTitle('User "' + record?.name + '"');
  const dataProvider = useDataProvider();
  const [tabValue, setTabValue] = React.useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [userCountry, setUserCountry] = useState();
  const [userAddress, setUserAddress] = useState<Address | null>(null);
  const [insolvencyResult, setInsolvencyResult] = useState<string | null>(null);

  const handleChange = useCallback(
    (_event: React.SyntheticEvent, newValue: number) => {
      setTabValue(newValue);
    },
    [setTabValue]
  );

  useEffect(() => {
    if (
      record?.claimsAsDebtor &&
      record?.claimsAsDebtor.length &&
      (!record?.roles || !record?.roles?.toString().includes("creditor"))
    ) {
      setTabValue(1);
    } else if (
      ((record?.claimsAsContact && record?.claimsAsContact.length) ||
        record?.userType?.toString().includes("AdditionalContact")) &&
      !record?.claimsAsDebtor &&
      !record?.claimsAsDebtor?.length
    ) {
      setTabValue(2);
    }
  }, [record]);

  useEffect(() => {
    if (record?.address) {
      dataProvider
        .getOne<Address>("Address", { id: record?.address.id })
        .then(({ data }) => {
          setUserAddress(data);
        });
    }
  }, [record?.address, dataProvider]);

  const handleCheckInsolvency: () => Promise<void> = async () => {
    setIsLoading(true);
    try {
      const authHeader = {
        headers: {
          Authorization: localStorage.getItem("credentials") || "",
          "Content-Type": "application/json",
        },
      };

      const response: Promise<void> = fetch(
        process.env.REACT_APP_SERVER_URL + "/api/activities/checkInsolvency",
        {
          method: "POST",
          body: JSON.stringify({
            businessName: record.businessName ?? null,
            contactName: record?.name ?? null,
            location: userAddress?.town ?? null,
          }),
          ...authHeader,
        }
      )
        .then((response) => response.json())
        .then((responseJson) => {
          setInsolvencyResult(
            responseJson?.results?.length > 0 ? "Insolvent" : "Not Insolvent"
          );
          setIsLoading(false);
        });
      return response;
    } catch (error) {
      console.log("error", error);
      setIsLoading(false);
      notify("Error checking insolvency", "error");
      return;
    }
  };

  return (
    <Card variant={"elevation"}>
      <TabbedShowLayoutStyled>
        <AdminTab label="General">
          <Grid
            container
            spacing={2}
            style={{ width: "100%", padding: "1rem" }}
          >
            <Grid item xs={12} md={6}>
              <Typography variant={"body2"} style={{ fontWeight: 600 }}>
                {"User Information:"}
              </Typography>
              <MuiList>
                <ListItem alignItems={"flex-start"}>
                  <ListItemText
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                    primary={
                      insolvencyResult ? (
                        <Typography
                          variant="body2"
                          style={{ marginTop: "10px" }}
                        >
                          Insolvency Status:{" "}
                        </Typography>
                      ) : (
                        <Button
                          variant="outlined"
                          size="small"
                          onClick={handleCheckInsolvency}
                          label="Check Insolvency"
                          disabled={insolvencyResult !== null || isLoading}
                        />
                      )
                    }
                    secondary={
                      <div style={{ marginTop: "20px" }}>
                        {insolvencyResult && (
                          <Typography
                            style={{
                              color:
                                insolvencyResult === "Insolvent"
                                  ? "red"
                                  : "green",
                            }}
                          >
                            {insolvencyResult}
                          </Typography>
                        )}
                        {isLoading && <CircularProgress size={15} />}
                      </div>
                    }
                  ></ListItemText>
                </ListItem>
                <Divider />
                <ListItem alignItems={"flex-start"}>
                  <ListItemText
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                    primary={
                      <Typography variant="caption">Username</Typography>
                    }
                    secondary={<TextField label="Username" source="username" />}
                  ></ListItemText>
                </ListItem>
                <Divider />
                <ListItem alignItems={"flex-start"}>
                  <ListItemText
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                    primary={
                      <Typography variant="caption">Contact Name</Typography>
                    }
                    secondary={
                      <TextField label="Contact Name" source="contactName" />
                    }
                  ></ListItemText>
                </ListItem>
                <Divider />
                <ListItem alignItems={"flex-start"}>
                  <ListItemText
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                    primary={
                      <Typography variant="caption">Business Name</Typography>
                    }
                    secondary={<UserBusinessName />}
                  ></ListItemText>
                </ListItem>
                <Divider />

                <ListItem alignItems={"flex-start"}>
                  <ListItemText
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                    primary={<Typography variant="caption">Email</Typography>}
                    secondary={<UserEmail />}
                  ></ListItemText>
                </ListItem>
                <Divider />

                <ListItem alignItems={"flex-start"}>
                  <ListItemText
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                    primary={<Typography variant="caption">Phone</Typography>}
                    secondary={<PhoneNumber />}
                  ></ListItemText>
                </ListItem>
                <Divider />

                <ListItem alignItems={"flex-start"}>
                  <ListItemText
                    secondary={
                      <ListItemText
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "flex-start",
                          justifyContent: "space-between",
                        }}
                        primary={
                          <Typography variant="caption" color="textPrimary">
                            Address:
                          </Typography>
                        }
                        secondary={
                          <ListItemText
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                              justifyContent: "space-between",
                            }}
                            primary={
                              <ReferenceField
                                link="show"
                                label="Address"
                                source="address.id"
                                reference="Address"
                              >
                                <div
                                  style={{
                                    paddingLeft: 20,
                                    display: "flex",
                                    alignItems: "flex-end",
                                    flexDirection: "column",
                                    backgroundColor: theme.palette.info.light,
                                    padding: "5px 10px",
                                    marginRight: 8,
                                    fontSize: 12,
                                    fontWeight: "bold",
                                    borderRadius: 8,
                                    color: theme.palette.background.paper,
                                    width: "100%",
                                  }}
                                >
                                  <TextField source={"addressLine1"} />
                                  <TextField source={"postalcode"} />
                                  <TextField source={"town"} />
                                  <FunctionField
                                    label=""
                                    render={(record: any) => {
                                      if (record?.country && !userCountry) {
                                        setUserCountry(record.country);
                                      }

                                      const iso2CoutryCode = searchMapByKey(
                                        ADDRESS_COUNTRY_SHORT,
                                        record.country as EnumAddressCountry
                                      );
                                      if (!iso2CoutryCode) {
                                        return null;
                                      }

                                      return (
                                        <span
                                          style={{
                                            display: "flex",
                                            alignItems: "center",
                                            color:
                                              theme.palette.background.paper,
                                          }}
                                        >
                                          {record?.country
                                            ? ADDRESS_COUNTRY_READABLE.get(
                                                record.country
                                              )
                                            : ""}
                                          <ReactCountryFlag
                                            countryCode={iso2CoutryCode}
                                            svg
                                            style={{ marginLeft: 4 }}
                                          />
                                          {(!record.verificationStatus ||
                                            record.verificationStatus ===
                                              "UNKNOWN") && (
                                            <icons.Help
                                              style={{
                                                height: 15,
                                                color:
                                                  theme.palette.warning.light,
                                              }}
                                            />
                                          )}
                                          {record.verificationStatus ===
                                            "VALID" && (
                                            <icons.VerifiedUser
                                              style={{
                                                height: 15,
                                                color:
                                                  theme.palette.success.main,
                                              }}
                                            />
                                          )}
                                          {record.verificationStatus ===
                                            "INVALID" && (
                                            <icons.RemoveCircle
                                              style={{
                                                height: 15,
                                                color: theme.palette.error.main,
                                              }}
                                            />
                                          )}
                                        </span>
                                      );
                                    }}
                                  />
                                </div>
                              </ReferenceField>
                            }
                          ></ListItemText>
                        }
                      ></ListItemText>
                    }
                  ></ListItemText>
                </ListItem>
                <Divider />

                <ListItem alignItems={"flex-start"}>
                  <ListItemText
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                    primary={
                      <Typography variant="caption">Birthday</Typography>
                    }
                    secondary={
                      <DateField
                        label="Birthday"
                        source="birthday"
                        locales="de-DE"
                        options={{
                          month: "2-digit",
                          day: "2-digit",
                          year: "numeric",
                          timeZone: "Europe/Berlin",
                        }}
                      />
                    }
                  ></ListItemText>
                </ListItem>
                <Divider />
                <ListItem alignItems={"flex-start"}>
                  <ListItemText
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                    primary={
                      <Typography variant="caption">Last Login</Typography>
                    }
                    secondary={
                      <DateField
                        label="Last Login"
                        source="lastLogin"
                        locales="de-DE"
                        options={{
                          month: "2-digit",
                          day: "2-digit",
                          year: "numeric",
                          hour: "2-digit",
                          minute: "2-digit",
                          timeZone: "Europe/Berlin",
                        }}
                      />
                    }
                  ></ListItemText>
                </ListItem>
                <Divider />

                <ListItem alignItems={"flex-start"}>
                  <ListItemText
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                    primary={
                      <Typography variant="caption">
                        Operational Recipients
                      </Typography>
                    }
                    secondary={
                      <FunctionField
                        label="Operational Recipients"
                        source="operationalRecipients"
                        render={(record) => (
                          <span
                            style={{
                              fontWeight: 600,
                              color: theme.palette.info.dark,
                              fontSize: 12,
                              borderRadius: 8,
                              paddingLeft: 10,
                              marginLeft: 0,
                            }}
                          >
                            {record.operationalRecipients?.join(", ") || ""}
                          </span>
                        )}
                      />
                    }
                  ></ListItemText>
                </ListItem>
              </MuiList>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant={"body2"} style={{ fontWeight: 600 }}>
                {"Other Information:"}
              </Typography>
              <MuiList>
                <ListItem alignItems={"flex-start"}>
                  <ListItemText
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                    primary={<Typography variant="caption">ID</Typography>}
                    secondary={
                      <ReferenceField
                        label="Claim"
                        source="claim.id"
                        reference="Claim"
                        link="show"
                      >
                        <TextField label="Id" source="id" />
                      </ReferenceField>
                    }
                  ></ListItemText>
                </ListItem>
                <Divider />

                <ListItem alignItems={"flex-start"}>
                  <ListItemText
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                    primary={
                      <Typography variant="caption">User Type</Typography>
                    }
                    secondary={
                      <TextField label="User Type" source="userType" />
                    }
                  ></ListItemText>
                </ListItem>
                <Divider />
                <ListItem alignItems={"flex-start"}>
                  <ListItemText
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                    primary={
                      <Typography variant="caption">Created At</Typography>
                    }
                    secondary={
                      <DateField
                        source="createdAt"
                        label="Created At"
                        locales="de-DE"
                        options={{
                          month: "2-digit",
                          day: "2-digit",
                          year: "numeric",
                          hour: "numeric",
                          minute: "numeric",
                          timeZone: "Europe/Berlin",
                        }}
                      />
                    }
                  ></ListItemText>
                </ListItem>
                <Divider />

                <ListItem alignItems={"flex-start"}>
                  <ListItemText
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                    primary={
                      <Typography variant="caption">Updated At</Typography>
                    }
                    secondary={
                      <DateField
                        source="updatedAt"
                        label="Updated At"
                        locales="de-DE"
                        options={{
                          month: "2-digit",
                          day: "2-digit",
                          year: "numeric",
                          hour: "numeric",
                          minute: "numeric",
                          timeZone: "Europe/Berlin",
                        }}
                      />
                    }
                  ></ListItemText>
                </ListItem>
                <Divider />
                <ListItem alignItems={"flex-start"}>
                  <ListItemText
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                    primary={<Typography variant="caption">Roles</Typography>}
                    secondary={
                      <FunctionField
                        label="Roles"
                        render={(record) => <RolesField record={record} />}
                      />
                    }
                  ></ListItemText>
                </ListItem>
                <Divider />
                <ListItem alignItems={"flex-start"}>
                  <ListItemText
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                    primary={
                      <Typography variant="caption">Is verified</Typography>
                    }
                    secondary={
                      <BooleanField label="Is verified" source="isVerified" />
                    }
                  ></ListItemText>
                </ListItem>
                <Divider />
                <ListItem alignItems={"flex-start"}>
                  <ListItemText
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                    primary={
                      <Typography variant="caption">
                        Partner lawyer communication allowed
                      </Typography>
                    }
                    secondary={
                      <BooleanField
                        label="Partner lawyer communication allowed"
                        source="allowPartnerLawyerInteraction"
                      />
                    }
                  ></ListItemText>
                </ListItem>
                <Divider />

                <ListItem alignItems={"flex-start"}>
                  <ListItemText
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                    primary={
                      <Typography variant="caption">Monthly Billing</Typography>
                    }
                    secondary={
                      <BooleanField
                        label="Partner lawyer communication allowed"
                        source="useMonthlyBilling"
                      />
                    }
                  ></ListItemText>
                </ListItem>
                <Divider />

                <ListItem alignItems={"flex-start"}>
                  <ListItemText
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                    primary={
                      <Typography variant="caption">Is insolvent?</Typography>
                    }
                    secondary={
                      <BooleanField label="Is insolvent" source="isInsolvent" />
                    }
                  ></ListItemText>
                </ListItem>
                <Divider />

                <ListItem alignItems={"flex-start"}>
                  <ListItemText
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                    primary={
                      <Typography variant="caption">Insolvency Date</Typography>
                    }
                    secondary={
                      <DateField
                        source="insolvencyDate"
                        label="Insolvency Date"
                        locales="de-DE"
                        options={{
                          month: "2-digit",
                          day: "2-digit",
                          year: "numeric",
                          timeZone: "Europe/Berlin",
                        }}
                      />
                    }
                  ></ListItemText>
                </ListItem>
                <Divider />
              </MuiList>
            </Grid>
            <Grid item xs={12} md={6}>
              <MuiList>
                <Typography variant={"body2"} style={{ fontWeight: 600 }}>
                  {"Business Information:"}
                </Typography>

                <ListItem alignItems={"flex-start"}>
                  <ListItemText
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                    primary={<Typography variant="caption">ID</Typography>}
                    secondary={<TextField label="ID" source="id" />}
                  ></ListItemText>
                </ListItem>
                <Divider />

                <ListItem alignItems={"flex-start"}>
                  <ListItemText
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                    primary={
                      <Typography variant="caption">Business Type</Typography>
                    }
                    secondary={
                      <TextField label="Business Type" source="businessType" />
                    }
                  ></ListItemText>
                </ListItem>
                <Divider />

                <ListItem alignItems={"flex-start"}>
                  <ListItemText
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                    primary={
                      <Typography variant="caption">
                        Business Contact Relation
                      </Typography>
                    }
                    secondary={
                      <TextField
                        label="Business Contact Relation"
                        source="businessContactRelationType"
                      />
                    }
                  ></ListItemText>
                </ListItem>
                <Divider />

                <ListItem alignItems={"flex-start"}>
                  <ListItemText
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                    primary={
                      <Typography variant="caption">Industry</Typography>
                    }
                    secondary={<TextField label="Industry" source="industry" />}
                  ></ListItemText>
                </ListItem>
              </MuiList>
            </Grid>
            <Grid item xs={12} md={6}>
              <MuiList style={{ marginTop: 14 }}>
                <ListItem alignItems={"flex-start"}>
                  <ListItemText
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                    primary={
                      <Typography variant="caption">
                        Tax Deduction Allowed
                      </Typography>
                    }
                    secondary={
                      <BooleanField
                        label="Tax Deduction Allowed"
                        source="taxDeductionAllowed"
                      />
                    }
                  ></ListItemText>
                </ListItem>
                <Divider />
                <ListItem alignItems={"flex-start"}>
                  <ListItemText
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                    primary={<Typography variant="caption">VIP</Typography>}
                    secondary={<BooleanField label="VIP" source="isVip" />}
                  ></ListItemText>
                </ListItem>
                <Divider />
                <ListItem alignItems={"flex-start"}>
                  <ListItemText
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                    primary={<Typography variant="caption">Vat ID</Typography>}
                    secondary={<TextField label="VAT Id" source="vatId" />}
                  ></ListItemText>
                </ListItem>
              </MuiList>
            </Grid>
          </Grid>
        </AdminTab>
        <AdminTab label="Claims">
          <Tabs
            value={tabValue}
            onChange={handleChange}
            style={{
              marginTop: 10,
              marginBottom: 15,
              background: "rgba(255, 255, 255, 0.5)",
              borderWidth: 1,
              borderStyle: "solid",
              borderColor: "rgba(0, 0, 0, 0.12)",
              borderRadius: 8,
              width: "100%",
            }}
          >
            <Tab label="As Creditor" />
            <Tab label="As Debtor" />
            <Tab label="As Contact" />
          </Tabs>
          <div
            role="tabpanel"
            hidden={tabValue !== 0}
            id={`parent-tabpanel-${0}`}
          >
            <ClaimsAsCreditor />
          </div>

          <div
            role="tabpanel"
            hidden={tabValue !== 1}
            id={`parent-tabpanel-${1}`}
          >
            <ClaimsAsDebtor />
          </div>

          <div
            role="tabpanel"
            hidden={tabValue !== 2}
            id={`parent-tabpanel-${2}`}
          >
            <ClaimsAsContact />
          </div>
        </AdminTab>
        <AdminTab label="Preferences">
          <Grid
            container
            spacing={2}
            style={{ width: "100%", marginTop: "1rem" }}
          >
            <Grid item xs={12} md={6}>
              <Typography
                variant="subtitle1"
                style={{
                  fontWeight: 600,
                  paddingLeft: "1rem",
                }}
              >
                User Preferences:
              </Typography>
              <SimpleForm toolbar={false}>
                <NonInput>
                  <MuiList>
                    <ListItem alignItems={"flex-start"}>
                      <ListItemText
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                        primary={
                          <Typography variant="caption">
                            Resarch (Minimum)
                          </Typography>
                        }
                        secondary={
                          <Typography>
                            <TextField label="User Type" source="userType" />
                            {" - ("}
                            <NumberField
                              label="Research"
                              source="researchMinimum"
                              locales={"de-DE"}
                            />
                            {")"}
                          </Typography>
                        }
                      ></ListItemText>
                      <Divider />
                    </ListItem>
                    <Divider />

                    <ListItem alignItems={"flex-start"}>
                      <ListItemText
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                        primary={
                          <Typography variant="caption">Image URL</Typography>
                        }
                        secondary={
                          <FunctionField
                            label="Image"
                            render={(record: any) => (
                              <span>
                                {record.imageUrl && (
                                  <img
                                    src={record.imageUrl}
                                    alt={record.businessName}
                                    style={{ maxWidth: 200 }}
                                  />
                                )}
                              </span>
                            )}
                          />
                        }
                      ></ListItemText>
                      <Divider />
                    </ListItem>
                    <Divider />

                    <ListItem alignItems={"flex-start"}>
                      <ListItemText
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                        primary={
                          <Typography variant="caption">
                            Maximum Payment Plan Months
                          </Typography>
                        }
                        secondary={
                          <NumberField
                            label="Maximum Payment Plan Months"
                            source="maximumPaymentPlanMonths"
                            locales={"de-DE"}
                          />
                        }
                      ></ListItemText>
                      <Divider />
                    </ListItem>
                    <Divider />

                    <ListItem alignItems={"flex-start"}>
                      <ListItemText
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                        primary={
                          <Typography variant="caption">
                            Vergleiche (Custom)
                          </Typography>
                        }
                        secondary={
                          <ArrayField
                            source="specificAutoSettlementInPercent"
                            label="Vergleiche (Custom)"
                          >
                            <Datagrid>
                              <TextField source="key" label="Range" />
                              <NumberField source="value" label="%" />
                            </Datagrid>
                          </ArrayField>
                        }
                      ></ListItemText>
                      <Divider />
                    </ListItem>
                    <Divider />

                    <ListItem alignItems={"flex-start"}>
                      <ListItemText
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                        primary={
                          <Typography variant="caption">
                            Success Fee %
                          </Typography>
                        }
                        secondary={
                          <NumberField
                            label="Success Fee %"
                            source="successFeeInPercent"
                            locales={"de-DE"}
                          />
                        }
                      ></ListItemText>
                      <Divider />
                    </ListItem>
                    <Divider />

                    <ListItem alignItems={"flex-start"}>
                      <ListItemText
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                        primary={
                          <Typography variant="caption">
                            Erfolgspauschale (Custom)
                          </Typography>
                        }
                        secondary={
                          <ArrayField
                            source="specificSuccessFeeInPercent"
                            label="Erfolgspauschale (Custom)"
                          >
                            <Datagrid>
                              <TextField source="key" label="Country" />
                              <NumberField source="value" label="%" />
                            </Datagrid>
                          </ArrayField>
                        }
                      ></ListItemText>
                      <Divider />
                    </ListItem>
                    <Divider />

                    <ListItem alignItems={"flex-start"}>
                      <ListItemText
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                        primary={
                          <Typography variant="caption">Manager</Typography>
                        }
                        secondary={
                          <ReferenceField
                            link="show"
                            label="Manager"
                            source="manager.id"
                            reference="User"
                          >
                            <TextField source={"contactName"} />
                          </ReferenceField>
                        }
                      ></ListItemText>
                      <Divider />
                    </ListItem>
                    <Divider />

                    <ListItem alignItems={"flex-start"}>
                      <ListItemText
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                        primary={
                          <Typography variant="caption">Integration</Typography>
                        }
                        secondary={
                          <ReferenceField
                            label="Integration"
                            source="integration.id"
                            reference="Integration"
                          >
                            <TextField source={INTEGRATION_TITLE_FIELD} />
                          </ReferenceField>
                        }
                      ></ListItemText>
                      <Divider />
                    </ListItem>
                    <Divider />
                  </MuiList>
                </NonInput>
              </SimpleForm>
            </Grid>
            <Grid item xs={12} md={6}>
              <SimpleForm toolbar={false}>
                <NonInput>
                  <MuiList style={{ marginTop: "2rem" }}>
                    <ListItem alignItems={"flex-start"}>
                      <ListItemText
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                        primary={
                          <Typography variant="caption">
                            Financial Recipients
                          </Typography>
                        }
                        secondary={
                          <FunctionField
                            label="Financial Recipients"
                            source="financialRecipients"
                            render={(record) =>
                              record.financialRecipients?.join(", ") || ""
                            }
                          />
                        }
                      ></ListItemText>
                      <Divider />
                    </ListItem>
                    <Divider />

                    <ListItem alignItems={"flex-start"}>
                      <ListItemText
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                        primary={
                          <Typography variant="caption">
                            Analytical Recipients
                          </Typography>
                        }
                        secondary={
                          <FunctionField
                            label="Analytical Recipients"
                            source="analyticalRecipients"
                            render={(record) =>
                              record.analyticalRecipients?.join(", ") || ""
                            }
                          />
                        }
                      ></ListItemText>
                      <Divider />
                    </ListItem>
                    <Divider />

                    <ListItem alignItems={"flex-start"}>
                      <ListItemText
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                        primary={
                          <Typography variant="caption">
                            Custom Clauses
                          </Typography>
                        }
                        secondary={
                          <TextField
                            label="Custom Clauses"
                            source="customClauses"
                          />
                        }
                      ></ListItemText>
                      <Divider />
                    </ListItem>
                    <Divider />

                    <ListItem alignItems={"flex-start"}>
                      <ListItemText
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                        primary={
                          <Typography variant="caption">UTM Source</Typography>
                        }
                        secondary={
                          <TextField label="Source" source="utmSource" />
                        }
                      ></ListItemText>
                      <Divider />
                    </ListItem>
                    <Divider />

                    <ListItem alignItems={"flex-start"}>
                      <ListItemText
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                        primary={
                          <Typography variant="caption">
                            Litigation (Minimum)
                          </Typography>
                        }
                        secondary={
                          <Typography>
                            <TextField label="Litigation" source="litigation" />
                            {" - ("}
                            <NumberField
                              label="Litigation"
                              source="litigationMinimum"
                              locales={"de-DE"}
                              defaultValue={0}
                            />
                            {")"}
                          </Typography>
                        }
                      ></ListItemText>
                      <Divider />
                    </ListItem>
                    <Divider />

                    <ListItem alignItems={"flex-start"}>
                      <ListItemText
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                        primary={
                          <Typography variant="caption">
                            Document Instructions
                          </Typography>
                        }
                        secondary={
                          <RichTextField
                            label="Document Instructions"
                            source="documentInstructions"
                            style={{ whiteSpace: "pre-wrap" }}
                          />
                        }
                      ></ListItemText>
                      <Divider />
                    </ListItem>
                    <Divider />

                    <ListItem alignItems={"flex-start"}>
                      <ListItemText
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                        primary={
                          <Typography variant="caption">
                            Standard Debtor Objections
                          </Typography>
                        }
                        secondary={
                          <RichTextField
                            label="Standard Debtor Objections"
                            source="standardObjections"
                            style={{ whiteSpace: "pre-wrap" }}
                          />
                        }
                      ></ListItemText>
                      <Divider />
                    </ListItem>
                    <Divider />

                    <ListItem alignItems={"flex-start"}>
                      <ListItemText
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                        primary={
                          <Typography variant="caption">
                            Operator Category
                          </Typography>
                        }
                        secondary={
                          <RichTextField
                            label="Operator Category"
                            source="operatorCategory"
                            style={{ whiteSpace: "pre-wrap" }}
                          />
                        }
                      ></ListItemText>
                      <Divider />
                    </ListItem>
                    <Divider />

                    <ListItem alignItems={"flex-start"}>
                      <ListItemText
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                        primary={
                          <Typography variant="caption">Comment</Typography>
                        }
                        secondary={
                          <RichTextField
                            label="Comment"
                            source="comment"
                            style={{ whiteSpace: "pre-wrap" }}
                          />
                        }
                      ></ListItemText>
                      <Divider />
                    </ListItem>
                    <Divider />

                    {record?.id && (
                      <List
                        resource="User"
                        basePath="/User"
                        exporter={false}
                        title={"Operators"}
                        actions={false}
                        filter={{
                          usersAsOperator: {
                            some: { id: { equals: record?.id } },
                          },
                        }}
                        filterDefaultValues={{
                          usersAsOperator: {
                            some: { id: { equals: record?.id } },
                          },
                        }}
                        perPage={50}
                        pagination={null}
                        sort={{ field: "contactName", order: "DESC" }}
                      >
                        <Datagrid
                          optimized
                          size="medium"
                          isRowSelectable={() => false}
                          hasBulkActions={false}
                          rowClick="show"
                        >
                          <TextField label="Name" source="contactName" />
                        </Datagrid>
                      </List>
                    )}
                  </MuiList>
                </NonInput>
              </SimpleForm>
            </Grid>
          </Grid>
        </AdminTab>

        <AdminTab label="Payment Information">
          <Grid
            container
            spacing={2}
            style={{ width: "100%", marginTop: "1rem" }}
          >
            <Grid item xs={12}>
              <PaymentInformations />
            </Grid>
          </Grid>
        </AdminTab>

        {record?.isVip && (
          <Tooltip title={"User is VIP"}>
            <div
              style={{
                position: "absolute",
                top: 10,
                right: 60,
                color: "rgb(246, 170, 80)",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography style={{ paddingRight: 10, paddingTop: 2 }}>
                VIP
              </Typography>
              <icons.StarBorderOutlined />
            </div>
          </Tooltip>
        )}
      </TabbedShowLayoutStyled>
    </Card>
  );
};

const ClaimsAsDebtor = React.memo(() => {
  const [showClaimsAsDebtorExport, setShowClaimsAsDebtorExport] =
    useState(false);
  const record = useRecordContext();

  const handleExportButtonClick = useCallback(() => {
    setShowClaimsAsDebtorExport(true);
  }, []);

  const [hasLawyer, setHasLawyer] = useState("");

  return (
    <>
      <List
        resource="Claim"
        basePath="/Claim"
        title="Claims as debtor"
        exporter={false}
        pagination={<Pagination rowsPerPageOptions={[25, 50, 100, 250]} />}
        perPage={25}
        filter={{
          debtor: {
            id: record.id,
          },
          ...(hasLawyer === "true"
            ? {
                contacts: {
                  some: {
                    userType: "Lawyer",
                  },
                },
              }
            : hasLawyer === "false"
            ? {
                contacts: {
                  none: {
                    userType: "Lawyer",
                  },
                },
              }
            : { contacts: {} }),
        }}
        filterDefaultValues={{ debtor: { id: record.id } }}
        filters={
          <ClaimFilter hasLawyer={hasLawyer} setHasLawyer={setHasLawyer} />
        }
        sort={{ field: "createdAt", order: "DESC" }}
        actions={false}
      >
        <>
          <ClaimListItems />
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              marginTop: "0.5rem",
            }}
          >
            <ClaimTotalsRow />
            {!showClaimsAsDebtorExport && (
              <Button
                variant="outlined"
                size="medium"
                onClick={handleExportButtonClick}
                label="Prepare Claim Export"
              />
            )}
          </div>

          {showClaimsAsDebtorExport && <ClaimExportButton />}
        </>
      </List>
    </>
  );
});

const ClaimsAsContact = React.memo(() => {
  const [showClaimsAsContactExport, setShowClaimsAsContactExport] =
    useState(false);
  const record = useRecordContext();

  const handleExportButtonClick = useCallback(() => {
    setShowClaimsAsContactExport(true);
  }, []);

  if (!record?.userType?.includes("AdditionalContact")) {
    return null;
  }

  return (
    <>
      <List
        resource="Claim"
        basePath="/Claim"
        title="Claims as contact"
        exporter={false}
        pagination={<Pagination rowsPerPageOptions={[25, 50, 100, 250]} />}
        perPage={25}
        filter={{
          contacts: {
            some: {
              id: { equals: record.id },
            },
          },
        }}
        filterDefaultValues={{
          contacts: {
            some: {
              id: { equals: record.id },
            },
          },
        }}
        sort={{ field: "createdAt", order: "DESC" }}
        actions={false}
      >
        <>
          <ClaimListItems />
          <ClaimTotalsRow />
          <br />
          {!showClaimsAsContactExport && (
            <Button
              variant="outlined"
              size="medium"
              onClick={handleExportButtonClick}
              label="Prepare Claim Export"
            />
          )}
          {showClaimsAsContactExport && <ClaimExportButton />}
        </>
      </List>
    </>
  );
});

const PaymentInformations = React.memo(() => {
  const record = useRecordContext();

  return (
    <>
      <Typography
        variant="subtitle1"
        style={{ fontWeight: 600, marginBottom: "1rem" }}
      >
        Payment Information:
      </Typography>
      <List
        resource="PaymentInformation"
        basePath="/PaymentInformation"
        title="Payment Information"
        exporter={false}
        pagination={<Pagination rowsPerPageOptions={[25, 50, 100, 250]} />}
        perPage={25}
        filter={{
          user: { id: record.id },
        }}
        filterDefaultValues={{
          user: { id: record.id },
        }}
        sort={{ field: "createdAt", order: "DESC" }}
        actions={false}
        className="overflow-manage"
      >
        <>
          <Datagrid rowClick="show" optimized>
            <TextField label="Currency" source="currency" emptyText="all" />
            <TextField label="Account" source="account" />
            <TextField label="Bank" source="bankIdentifier" />
            <TextField label="Method" source="method" />
            <DateField
              source="createdAt"
              label="Created"
              locales="de-DE"
              options={{
                month: "2-digit",
                day: "2-digit",
                year: "numeric",
                timeZone: "Europe/Berlin",
              }}
            />
            <DateField
              source="updatedAt"
              label="Updated"
              locales="de-DE"
              options={{
                month: "2-digit",
                day: "2-digit",
                year: "numeric",
                timeZone: "Europe/Berlin",
              }}
            />
          </Datagrid>
          <div style={{ margin: "1rem auto 0", maxWidth: 300 }}>
            <Button
              href={"/#/PaymentInformation/create?userId=" + record?.id}
              startIcon={<icons.AddCircleOutlined />}
              style={{
                fontSize: 12,
                fontWeight: "bold",
                minWidth: "100%",
              }}
              size={"large"}
              label="Add new"
              variant="contained"
            />
          </div>
        </>
      </List>
    </>
  );
});
