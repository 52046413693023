import { Card, CardContent, Typography } from "@material-ui/core";
import { CustomSlider } from "../../CustomSlider";

const SidebarClaimCalculator = ({
  setSliderFactorCreditor,
  setSliderFactorDebtCollector,
  sliderFactorCreditor,
  sliderFactorDebtCollector,
}) => {
  const handleChangeFactorCreditor = (_event, newValue) => {
    setSliderFactorCreditor(newValue);
  };
  const handleChangeFactorDebtCollector = (_event, newValue) => {
    setSliderFactorDebtCollector(newValue);
  };

  function sliderFormat(value) {
    return `${value}%`;
  }

  return (
    <Card
      style={{
        marginBottom: 20,
      }}
    >
      <CardContent>
        <Typography
          variant="h6"
          style={{
            marginBottom: 10,
          }}
        >
          Vergleich
        </Typography>
        <Typography variant="caption">Gläubiger</Typography>
        <CustomSlider
          defaultValue={100}
          value={sliderFactorCreditor}
          onChange={handleChangeFactorCreditor}
          color="primary"
          valueLabelFormat={sliderFormat}
          step={1}
          valueLabelDisplay="auto"
        />
        <Typography variant="caption">Inkasso</Typography>
        <CustomSlider
          defaultValue={100}
          value={sliderFactorDebtCollector}
          onChange={handleChangeFactorDebtCollector}
          color="secondary"
          valueLabelFormat={sliderFormat}
          step={1}
          valueLabelDisplay="auto"
        />
      </CardContent>
    </Card>
  );
};

export default SidebarClaimCalculator;
