import qs from "qs";
import * as React from "react";
import {
  BooleanField,
  Datagrid,
  DateField,
  DateTimeInput,
  List,
  ListProps,
  NullableBooleanInput,
  ReferenceField,
  SelectInput,
  TextField,
  useGetList,
} from "react-admin";
import { useLocation } from "react-router-dom";
import Pagination from "../Components/Pagination";
import { getReadableSubjectByClaimAction } from "../api/activity/Activity";
import { User } from "../api/user/User";
import { CLAIM_TITLE_FIELD } from "../claim/ClaimTitle";

export const ActivityList = (props: ListProps): React.ReactElement => {
  const parsedLocation = qs.parse(useLocation().search.slice(1));
  let jsonFilter: any = {
    isPlanned: "",
  };

  if (parsedLocation.filter) {
    try {
      jsonFilter = JSON.parse(parsedLocation.filter as string);
    } catch (e) {}
  }

  const { data: usersData } = useGetList<User>(
    "User",
    { page: 1, perPage: 25 },
    { field: "createdAt", order: "ASC" },
    {
      roles: {
        array_contains: "manager",
      },
    }
  );

  const [isPlannedFilterValue, setIsPlannedFilterValue] = React.useState(
    jsonFilter.isPlanned?.toString()
  );
  const [latestDateFilterValue, setLatestDateFilterValue] = React.useState(
    jsonFilter.activityDate?.toString()
  );
  const [managerFilterValue, setManagerFilterValue] = React.useState(
    jsonFilter.manager?.toString()
  );

  const activityFilters = [
    <SelectInput
      alwaysOn
      source="activityType"
      label="Type"
      choices={[
        { label: "Outbound Call", value: "OutboundCall" },
        { label: "Outbound Mail", value: "OutboundMail" },
        { label: "Outbound Letter", value: "OutboundLetter" },
        { label: "Outbound WhatsApp", value: "OutboundWhatsapp" },
        { label: "Outbound SMS", value: "OutboundSms" },
        { label: "Internal Feedback", value: "InternalFeedback" },
        {
          label: "CreditorInformation",
          value: "CreditorInformation",
        },
        {
          label: "InternalDecision",
          value: "InternalDecision",
        },
        {
          label: "Research",
          value: "Research",
        },
        {
          label: "JudicialActivity",
          value: "JudicialActivity",
        },
        {
          label: "InsolvencyActivity",
          value: "InsolvencyActivity",
        },
        { label: "Creditor Feedback", value: "CreditorFeedback" },
        {
          label: "CreditorDecision",
          value: "CreditorDecision",
        },
        { label: "Response", value: "Response" },
        { label: "RecommendedOutboundMail", value: "RecommendedOutboundMail" },
        {
          label: "RecommendedInternalFeedback",
          value: "RecommendedInternalFeedback",
        },
        { label: "AiCreditorLetterSummary", value: "AiCreditorLetterSummary" },
        {
          label: "AiProposeLetterResponseToDebtorMail",
          value: "AiProposeLetterResponseToDebtorMail",
        },
        { label: "AiProposePaymentPlan", value: "AiProposePaymentPlan" },
        { label: "Other", value: "Other" },
      ]}
      optionText="label"
      optionValue="value"
    />,
    <SelectInput
      alwaysOn
      source="claimAction"
      label="Claim Action"
      choices={[
        {
          label: getReadableSubjectByClaimAction("FirstDunning"),
          value: "FirstDunning",
        },
        {
          label: getReadableSubjectByClaimAction("SecondDunning"),
          value: "SecondDunning",
        },
        {
          label: getReadableSubjectByClaimAction("ThirdDunning"),
          value: "ThirdDunning",
        },
        {
          label: getReadableSubjectByClaimAction("FourthDunning"),
          value: "FourthDunning",
        },
        {
          label: getReadableSubjectByClaimAction("FifthDunning"),
          value: "FifthDunning",
        },
        {
          label: getReadableSubjectByClaimAction("SixthDunning"),
          value: "SixthDunning",
        },
        {
          label: getReadableSubjectByClaimAction("FirstReminder"),
          value: "FirstReminder",
        },
        {
          label: getReadableSubjectByClaimAction("SecondReminder"),
          value: "SecondReminder",
        },
        {
          label: getReadableSubjectByClaimAction("ThirdReminder"),
          value: "ThirdReminder",
        },
        {
          label: getReadableSubjectByClaimAction("PhoneResearch"),
          value: "PhoneResearch",
        },
        {
          label: getReadableSubjectByClaimAction("EmailResearch"),
          value: "EmailResearch",
        },
        {
          label: getReadableSubjectByClaimAction("OnlineResearch"),
          value: "OnlineResearch",
        },
        {
          label: getReadableSubjectByClaimAction("PublicAuthorityRequest"),
          value: "PublicAuthorityRequest",
        },
        {
          label: getReadableSubjectByClaimAction("DefaultSummon"),
          value: "DefaultSummon",
        },
        {
          label: getReadableSubjectByClaimAction("Lawsuit"),
          value: "Lawsuit",
        },
        {
          label: getReadableSubjectByClaimAction("DebtCollectionTransfer"),
          value: "DebtCollectionTransfer",
        },
        {
          label: getReadableSubjectByClaimAction("DiscountOffer"),
          value: "DiscountOffer",
        },
      ]}
      optionText="label"
      optionValue="value"
    />,
    <SelectInput
      alwaysOn
      source="result"
      label="Result"
      choices={[
        { label: "NotReached", value: "NotReached" },
        { label: "ReachedPromisedToPay", value: "ReachedPromisedToPay" },
        { label: "Reached", value: "Reached" },
        { label: "ReachedIgnored", value: "ReachedIgnored" },
        { label: "ReachedRefusedToPay", value: "ReachedRefusedToPay" },
        {
          label: "ReachedRefusedCorrectness",
          value: "ReachedRefusedCorrectness",
        },
        {
          label: "ReachedPaymentPlanSetup",
          value: "ReachedPaymentPlanSetup",
        },
        {
          label: "ReachedPaymentPlanAdjustment",
          value: "ReachedPaymentPlanAdjustment",
        },
        {
          label: "ReachedPaymentPlanStop",
          value: "ReachedPaymentPlanStop",
        },
        {
          label: "Positive",
          value: "Positive",
        },
        {
          label: "Negative",
          value: "Negative",
        },
      ]}
      optionText="label"
      optionValue="value"
    />,
    <NullableBooleanInput
      source="isPlanned"
      alwaysOn
      value={isPlannedFilterValue}
      onChange={(event) => {
        setIsPlannedFilterValue(event?.target?.value);
      }}
    />,
    <SelectInput
      source="manager"
      label="Manager"
      value={managerFilterValue}
      onChange={(e) => {
        setManagerFilterValue(e.target.value);
      }}
      alwaysOn
      choices={[
        ...Object.entries(usersData).map(([_key, user]) => ({
          label: user.name,
          value: user.id,
        })),
      ]}
      optionText="label"
      optionValue="value"
    />,
    <DateTimeInput
      value={latestDateFilterValue}
      onChange={(event) => {
        setLatestDateFilterValue(event?.target?.value);
      }}
      alwaysOn
      source="activityDate"
      label="Latest Activity Date"
    />,
  ];

  return (
    <List
      {...props}
      exporter={false}
      bulkActionButtons={false}
      title={"Activities"}
      filter={{
        ...(isPlannedFilterValue !== "" && isPlannedFilterValue !== undefined
          ? {
              ...{
                isPlanned: { equals: isPlannedFilterValue === "true" },
              },
            }
          : {
              ...{
                isPlanned: { not: undefined },
              },
            }),
        ...(latestDateFilterValue !== "" && latestDateFilterValue !== undefined
          ? {
              ...{
                activityDate: { lte: latestDateFilterValue },
              },
            }
          : {
              ...{
                activityDate: { not: undefined },
              },
            }),
        ...(managerFilterValue &&
          managerFilterValue.length && {
            ...{
              manager: { id: managerFilterValue },
            },
          }),
      }}
      filters={activityFilters}
      filterDefaultValues={{
        isPlanned: null,
      }}
      perPage={50}
      empty={false}
      pagination={<Pagination />}
      sort={{ field: "activityDate", order: "DESC" }}
    >
      <Datagrid
        rowClick="show"
        optimized
        size="medium"
        style={{
          marginTop: "1rem",
        }}
      >
        <DateField
          label="Date"
          source="activityDate"
          locales="de-DE"
          options={{
            month: "2-digit",
            day: "2-digit",
            year: "numeric",
            hour: "numeric",
            minute: "numeric",
            timeZone: "Europe/Berlin",
          }}
        />
        <TextField label="Activity Type" source="activityType" />
        <TextField label="Result" source="result" />
        <BooleanField label="Planned" source="isPlanned" />
        <ReferenceField
          label="Claim"
          source="claim.id"
          reference="Claim"
          link="show"
        >
          <TextField source={CLAIM_TITLE_FIELD} />
        </ReferenceField>
        <TextField source="claimAction" label="Claim Action" />
        <TextField source="comment" label="Comment" />
      </Datagrid>
    </List>
  );
};
