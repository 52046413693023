export enum EnumIntegrationSoftware {
  Lexoffice = "Lexoffice",
  SevDesk = "SevDesk",
  FastBill = "FastBill",
  BuchhaltungsButler = "BuchhaltungsButler",
  Sage = "Sage",
  ZohoBooks = "ZohoBooks",
  Odoo = "Odoo",
  Chargebee = "Chargebee",
  Datev = "Datev",
  Billomat = "Billomat",
  Billbee = "Billbee",
  Xentral = "Xentral",
  Custom = "Custom",
  Easybill = "Easybill",
  Buhl = "Buhl",
  Stripe = "Stripe",
  Circuly = "Circuly",
  Payactive = "Payactive",
  Tentary = "Tentary",
}
