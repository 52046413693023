import {
  Divider,
  Grid,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@material-ui/core";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import { format } from "date-fns/format";
import * as React from "react";
import { useCallback, useEffect } from "react";
import {
  BooleanField,
  Button,
  DateField,
  FunctionField,
  ReferenceField,
  Show,
  ShowProps,
  SimpleShowLayout,
  TextField,
  useGetOne,
  useNotify,
  useRecordContext,
} from "react-admin";
import { Claim } from "../api/claim/Claim";
import { Payment } from "../api/payment/Payment";
import { NonInput } from "../Components/NonInputComponent";
import AppConfig from "../config/AppConfig";
import { PAYMENTPLAN_TITLE_FIELD } from "../paymentPlan/PaymentPlanTitle";
import { USER_TITLE_FIELD } from "../user/UserTitle";

let claimReference: string | null = "";

function handleFileClick(claimReference: string, url: string): void {
  const cleanClaimReference = claimReference?.replaceAll("/", "-");
  let urlToFetch = url.split(cleanClaimReference + "/").pop();
  urlToFetch = encodeURIComponent(urlToFetch || "");

  const authHeader = {
    headers: { Authorization: localStorage.getItem("credentials") || "" },
  };

  if (urlToFetch) {
    fetch(
      process.env.REACT_APP_SERVER_URL +
        "/api/claims/" +
        cleanClaimReference +
        "/file/" +
        urlToFetch,
      authHeader
    ).then((response) => {
      if (response.ok) {
        let anchor = document.createElement("a");
        document.body.appendChild(anchor);

        response.blob().then((blobby) => {
          const objectUrl = window.URL.createObjectURL(blobby);
          anchor.href = objectUrl;
          anchor.download = claimReference + "-" + urlToFetch;
          anchor.click();

          window.URL.revokeObjectURL(objectUrl);
        });
      }
    });
  }
}

const SepaDownloadButton = (): React.ReactElement => {
  const record = useRecordContext();
  const notify = useNotify();

  const { data: recipientData } = useGetOne(
    "User",
    record?.recipient?.id || 0,
    {
      enabled: !!record?.recipient?.id,
    }
  );

  const { data: paymentInformationData } = useGetOne(
    "PaymentInformation",
    recipientData?.paymentInformationIds?.[0] || 0,
    {
      enabled: !!recipientData?.paymentInformationIds?.length,
    }
  );

  const handleSepaDownload = () => {
    try {
      const today = new Date();
      const SEPA = require("sepa");
      const sepaExportDoc = new SEPA.Document("pain.001.001.03");

      sepaExportDoc.grpHdr.id = `debtist-singlepayment-${
        record.reference || record.id
      }-${format(today, "dd-MM-yyyy")}`;
      sepaExportDoc.grpHdr.created = today;
      sepaExportDoc.grpHdr.initiatorName = AppConfig.bank.accountHolder;

      const info = sepaExportDoc.createPaymentInfo();
      info.requestedExecutionDate = today;
      info.debtorIBAN = AppConfig.bank.iban;
      info.debtorBIC = AppConfig.bank.bic;
      info.debtorName = AppConfig.bank.accountHolder;
      info.batchBooking = false;

      const transfer = info.createTransaction();
      transfer.creditorName =
        recipientData?.businessName ||
        AppConfig.bank.companyAccount.accountHolder;
      transfer.creditorIBAN =
        paymentInformationData?.account?.replaceAll(" ", "") ||
        AppConfig.bank.companyAccount.iban;
      transfer.amount = record.amount || 0; // Ensure amount is a number
      transfer.currency = "EUR";
      transfer.remittanceInfo =
        (!!record.externalId ? record.externalId + " " : "") +
          record.reference || "";
      transfer.endToEndId = record.reference || record.id;

      info.addTransaction(transfer);
      sepaExportDoc.addPaymentInfo(info);

      // Download logic
      const blob = new Blob([sepaExportDoc.toString()], {
        type: "text/xml;charset=utf-8",
      });
      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = `sepa_export_${record.id}.xml`;
      document.body.appendChild(a);
      a.click();
      a.remove();
      URL.revokeObjectURL(url);

      notify("SEPA file downloaded successfully", "success");
    } catch (err) {
      console.error("Error generating SEPA file", err);
      notify("An error occurred while generating the SEPA file", "error");
    }
  };

  if (
    record.paymentType !== "DebtCollectorInboundPosition" &&
    (!paymentInformationData || !record.externalId)
  ) {
    return null;
  }

  return (
    <Button
      label="Download SEPA file"
      onClick={handleSepaDownload}
      disabled={record?.amount <= 0}
    />
  );
};

const PaymentFileUrl = (): React.ReactElement => {
  const record: Payment = useRecordContext();

  return (
    <>
      <TextField label="File URL" source="fileUrl" />
      {record?.fileUrl &&
        record.fileUrl !== null &&
        claimReference !== null && (
          <Button
            variant="outlined"
            size="medium"
            startIcon={<ArrowDownward />}
            style={{ marginLeft: 10 }}
            onClick={() => handleFileClick(claimReference!, record.fileUrl!)}
            label="Download File"
          />
        )}
    </>
  );
};

const ClaimReference = (): React.ReactElement => {
  const record: Claim = useRecordContext();

  useEffect(() => {
    claimReference = record?.reference;
  }, [record?.reference]);

  return <TextField source="reference" />;
};

export const PaymentShow = (props: ShowProps): React.ReactElement => {
  const handleKeyPress = useCallback(
    (event) => {
      if (event.target === document.body && event.key === "e") {
        window.open("/#/Payment/" + props.id + "/", "_self");
      }
    },
    [props.id]
  );

  useEffect(() => {
    // attach the event listener
    document.addEventListener("keydown", handleKeyPress);

    // remove the event listener
    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, [handleKeyPress]);

  return (
    <Show {...props}>
      <SimpleShowLayout>
        <NonInput>
          <Grid
            container
            spacing={4}
            className="relativity"
            style={{
              width: "100%",
              padding: "0rem 0rem 3rem",
            }}
          >
            <div className="id-wrapper">
              <Typography variant="caption">{"Payment ID: "}</Typography>
              <TextField label="ID" source="id" />
            </div>
            <Grid item xs={12} md={5}>
              <List>
                <ListItem alignItems={"flex-start"}>
                  <Typography variant="h6">{"Payment Details:"}</Typography>
                </ListItem>
                <ListItem alignItems={"flex-start"}>
                  <ListItemText
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                    primary={
                      <Typography variant="caption">Payment Date:</Typography>
                    }
                    secondary={
                      <DateField
                        label="Payment Date"
                        source="paymentDate"
                        locales="de-DE"
                        options={{
                          month: "2-digit",
                          day: "2-digit",
                          year: "numeric",
                          hour: "numeric",
                          minute: "numeric",
                          timeZone: "Europe/Berlin",
                        }}
                        style={{ fontWeight: 600 }}
                      />
                    }
                  ></ListItemText>
                </ListItem>
                <Divider component="li" />

                <ListItem alignItems={"flex-start"}>
                  <ListItemText
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                    primary={<Typography variant="caption">Claim:</Typography>}
                    secondary={
                      <ReferenceField
                        link="show"
                        label="Claim"
                        source="claim.id"
                        reference="Claim"
                      >
                        <ClaimReference />
                      </ReferenceField>
                    }
                  ></ListItemText>
                </ListItem>
                <Divider component="li" />

                <ListItem alignItems={"flex-start"}>
                  <ListItemText
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                    primary={
                      <Typography variant="caption">Reference:</Typography>
                    }
                    secondary={
                      <TextField
                        label="Reference"
                        source="reference"
                        style={{ fontWeight: 600 }}
                      />
                    }
                  ></ListItemText>
                </ListItem>
                <Divider component="li" />

                <ListItem alignItems={"flex-start"}>
                  <ListItemText
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                    primary={
                      <Typography variant="caption">External ID:</Typography>
                    }
                    secondary={
                      <TextField
                        label="External ID"
                        source="externalId"
                        style={{ fontWeight: 600 }}
                      />
                    }
                  ></ListItemText>
                </ListItem>
                <Divider component="li" />

                <ListItem alignItems={"flex-start"}>
                  <ListItemText
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                    primary={<Typography variant="caption">Type:</Typography>}
                    secondary={
                      <TextField
                        label="Payment Type"
                        source="paymentType"
                        style={{ fontWeight: 600 }}
                      />
                    }
                  ></ListItemText>
                </ListItem>
                <Divider component="li" />

                <ListItem alignItems={"flex-start"}>
                  <ListItemText
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                    primary={<Typography variant="caption">Plan:</Typography>}
                    secondary={
                      <ReferenceField
                        link="show"
                        label="Payment Plan"
                        source="paymentPlan.id"
                        reference="PaymentPlan"
                      >
                        <TextField source={PAYMENTPLAN_TITLE_FIELD} />
                      </ReferenceField>
                    }
                  ></ListItemText>
                </ListItem>
                <Divider component="li" />

                <ListItem alignItems={"flex-start"}>
                  <ListItemText
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                    primary={
                      <Typography variant="caption">Created at:</Typography>
                    }
                    secondary={
                      <DateField
                        source="createdAt"
                        label="Created At"
                        locales="de-DE"
                        options={{
                          month: "2-digit",
                          day: "2-digit",
                          year: "numeric",
                          hour: "numeric",
                          minute: "numeric",
                          timeZone: "Europe/Berlin",
                        }}
                        style={{ fontWeight: 600 }}
                      />
                    }
                  ></ListItemText>
                </ListItem>
                <Divider component="li" />

                <ListItem alignItems={"flex-start"}>
                  <ListItemText
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                    primary={
                      <Typography variant="caption">Updated at:</Typography>
                    }
                    secondary={
                      <DateField
                        source="updatedAt"
                        label="Updated At"
                        locales="de-DE"
                        options={{
                          month: "2-digit",
                          day: "2-digit",
                          year: "numeric",
                          hour: "numeric",
                          minute: "numeric",
                          timeZone: "Europe/Berlin",
                        }}
                      />
                    }
                  ></ListItemText>
                </ListItem>
              </List>
            </Grid>

            <Grid item xs={12} md={7}>
              <List>
                <ListItem
                  alignItems={"flex-start"}
                  className="heightForDesktop"
                ></ListItem>
                <ListItem alignItems={"flex-start"}>
                  <ListItemText
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                    primary={
                      <Typography variant="caption">File URL:</Typography>
                    }
                    secondary={<PaymentFileUrl />}
                  ></ListItemText>
                </ListItem>
                <Divider />
                <ListItem alignItems={"flex-start"}>
                  <ListItemText
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                    primary={
                      <Typography variant="caption">Is planned:</Typography>
                    }
                    secondary={
                      <BooleanField label="Is planned" source="isPlanned" />
                    }
                  ></ListItemText>
                </ListItem>
                <Divider />
                <ListItem alignItems={"flex-start"}>
                  <ListItemText
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                    primary={
                      <Typography variant="caption">Is tax free:</Typography>
                    }
                    secondary={
                      <BooleanField label="Is tax free" source="isTaxFree" />
                    }
                  ></ListItemText>
                </ListItem>
                <Divider />
                <ListItem alignItems={"flex-start"}>
                  <ListItemText
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                    primary={
                      <Typography variant="caption">
                        Conversion rate:
                      </Typography>
                    }
                    secondary={
                      <TextField
                        label="Conversion rate"
                        source="conversionRate"
                      />
                    }
                  ></ListItemText>
                </ListItem>
                <Divider />
                <ListItem alignItems={"flex-start"}>
                  <ListItemText
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                    primary={
                      <Typography variant="caption">Recipient:</Typography>
                    }
                    secondary={
                      <ReferenceField
                        link="show"
                        label="Recipient"
                        source="recipient.id"
                        reference="User"
                      >
                        <TextField source={USER_TITLE_FIELD} />
                      </ReferenceField>
                    }
                  ></ListItemText>
                </ListItem>
                <Divider />
                <ListItem alignItems={"flex-start"}>
                  <ListItemText
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                    primary={
                      <Typography variant="caption">SEPA Export:</Typography>
                    }
                    secondary={<SepaDownloadButton />}
                  />
                </ListItem>
              </List>
            </Grid>
            <div className="payment-plan-total" style={{ bottom: 0, left: 0 }}>
              <Typography variant="caption">Amount:</Typography>
              <FunctionField
                source="amount"
                label="Amount"
                sortable
                render={(record: any) => {
                  const numberFormat = Intl.NumberFormat("de-DE", {
                    style: "currency",
                    currency: record?.currency || "EUR",
                  });

                  return (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-end",
                        fontSize: 18,
                        padding: "0 0.5rem",
                        fontWeight: 600,
                      }}
                    >
                      {numberFormat.format(record?.amount || 0)}
                    </div>
                  );
                }}
              />
            </div>
          </Grid>
        </NonInput>
      </SimpleShowLayout>
    </Show>
  );
};
