import { TextInput } from "react-admin";
import { useForm, useFormState } from "react-final-form";

const EmailInput = () => {
  const form = useForm();
  const { values } = useFormState();

  const trimInput = (value) => {
    if (value) {
      return value.trim().replace(/^\.*|\.*$/g, ""); // Trim spaces and dots at start/end
    }
    return value;
  };

  return (
    <TextInput
      fullWidth
      label="Email"
      source="email"
      type="email"
      onPaste={async (e) => {
        e.preventDefault();
        let pasteContent = e.clipboardData.getData("text");
        pasteContent = pasteContent.trim().replace(/[^a-zA-Z0-9._%+-@]+/g, "");
        if (values.email) {
          if (values.email.endsWith(",") || values.email.endsWith(" ")) {
            form.change("email", values.email + pasteContent);
          } else {
            form.change("email", values.email + "," + pasteContent);
          }
        } else {
          form.change("email", pasteContent);
        }
      }}
      onBlur={(e) => {
        const trimmedValue = trimInput(e.target.value);
        form.change("email", trimmedValue);
      }}
      isRequired
    />
  );
};

export default EmailInput;
