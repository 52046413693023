import { Grid, makeStyles, Typography } from "@material-ui/core";
import { AddCircleOutlined } from "@material-ui/icons";
import qs from "qs";
import {
  AutocompleteArrayInput,
  AutocompleteInput,
  BooleanInput,
  Button,
  Datagrid,
  DateField,
  List,
  maxLength,
  NumberInput,
  Pagination,
  ReferenceArrayInput,
  ReferenceInput,
  TextField,
  TextInput,
  useRecordContext,
} from "react-admin";
import { useFormState } from "react-final-form";
import { useLocation } from "react-router-dom";
import { AddressTitle } from "../../address/AddressTitle";
import { UserTitle } from "../../user/UserTitle";
import AccountingFlagForm from "./steps/AccountingFlagForm";
import BasicInfoForm from "./steps/BasicInfoForm";
import CommentForm from "./steps/CommentForm";
import OperationFlagsForm from "./steps/OperationFlagForm";

const useStyles = makeStyles({
  stepContainer: {
    position: "relative",
    overflow: "hidden",
    padding: "0 3rem",
    minHeight: "400px",
  },
  step: {
    position: "absolute",
    width: "100%",
    transition: "all 0.3s ease-out",
    opacity: 0,
    transform: "translateX(50px)",
  },
  stepActive: {
    opacity: 1,
    transform: "translateX(0)",
    position: "relative",
  },
  stepPrev: {
    opacity: 0,
    transform: "translateX(-50px)",
  },
  stepNext: {
    opacity: 0,
    transform: "translateX(50px)",
  },
});

interface UserEditFormProps {
  activeStep: number;
  onlyShowEmployeePreferences?: boolean;
}

const AddressInput = () => {
  const record = useRecordContext();

  return (
    <ReferenceInput
      fullWidth
      filterToQuery={(searchText) => ({
        addressLine1: { startsWith: searchText || "*" },
      })}
      source="address.id"
      reference="Address"
      label="Address"
      create
    >
      <AutocompleteInput
        suggestionLimit={1}
        width="100%"
        createValue="-- NEW --"
        optionText={AddressTitle}
        clearAlwaysVisible
        onSelect={(selectedItem) => {
          if (selectedItem?.id === "-- NEW --") {
            window.open("/#/Address/create?userId=" + record.id, "_blank");
          }
        }}
      />
    </ReferenceInput>
  );
};

const PaymentInformationInput = () => {
  const record = useRecordContext();

  return (
    <>
      <Typography
        variant="subtitle1"
        style={{ fontWeight: 600, marginBottom: "1rem" }}
      >
        Payment Information:
      </Typography>
      <List
        resource="PaymentInformation"
        basePath="/PaymentInformation"
        title="Payment Information"
        exporter={false}
        pagination={<Pagination rowsPerPageOptions={[25, 50, 100, 250]} />}
        perPage={25}
        filter={{
          user: { id: record.id },
        }}
        filterDefaultValues={{
          user: { id: record.id },
        }}
        sort={{ field: "createdAt", order: "DESC" }}
        actions={false}
        className="overflow-manage"
      >
        <>
          <Datagrid rowClick="show" optimized>
            <TextField label="Currency" source="currency" emptyText="all" />
            <TextField label="Account" source="account" />
            <TextField label="Bank" source="bankIdentifier" />
            <TextField label="Method" source="method" />
            <DateField
              source="createdAt"
              label="Created"
              locales="de-DE"
              options={{
                month: "2-digit",
                day: "2-digit",
                year: "numeric",
                timeZone: "Europe/Berlin",
              }}
            />
            <DateField
              source="updatedAt"
              label="Updated"
              locales="de-DE"
              options={{
                month: "2-digit",
                day: "2-digit",
                year: "numeric",
                timeZone: "Europe/Berlin",
              }}
            />
          </Datagrid>
          <div style={{ margin: "1rem auto 0", maxWidth: 300 }}>
            <Button
              href={"/#/PaymentInformation/create?userId=" + record?.id}
              startIcon={<AddCircleOutlined />}
              style={{
                fontSize: 12,
                fontWeight: "bold",
                minWidth: "100%",
              }}
              size={"large"}
              label="Add new"
              variant="contained"
            />
          </div>
        </>
      </List>
    </>
  );
};

const SourceInput = (props: any) => {
  return <TextInput label="Source of lead" source="utmSource" {...props} />;
};

const UserEditForm = ({
  activeStep,
  onlyShowEmployeePreferences,
}: UserEditFormProps) => {
  const parsedLocation = qs.parse(useLocation().search.slice(1));
  const { values } = useFormState();
  const classes = useStyles();

  let isNormalUser = true;
  let isLaywerUser = false;
  let isEmployee = false;
  if (
    parsedLocation.userType &&
    parsedLocation.userType !== "Default" &&
    values &&
    values.userType !== "Default"
  ) {
    if (parsedLocation.userType === "Lawyer" || values.userType === "Lawyer") {
      isLaywerUser = true;
    }
    isNormalUser = false;
  }
  if (isNormalUser) {
    isEmployee = ["manager", "admin", "lightManager"].some((str) =>
      values.roles.includes(str)
    );
  }
  if (onlyShowEmployeePreferences) {
    return renderEmployeeSettings();
  }

  const getStepClass = (step: number) => {
    if (step === activeStep) return `${classes.step} ${classes.stepActive}`;
    if (step < activeStep) return `${classes.step} ${classes.stepPrev}`;
    return `${classes.step} ${classes.stepNext}`;
  };

  return (
    <>
      <div className={getStepClass(0)}>
        <BasicInfoForm
          isAdditionalContact={false}
          isInsolvencyAdministrator={false}
          isLaywerUser={isLaywerUser}
          isEdit
        />
        <Grid container spacing={4}>
          <Grid item xs={12} md={6} lg={4}>
            <AddressInput />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <ReferenceArrayInput
              filterToQuery={(searchText) => ({
                name: { startsWith: searchText },
                businessName: {
                  equals: "Debtist GmbH",
                },
                manager: {
                  id: "clf4crymt0000s601r43i14j7", // m.benedetti PROD
                },
                isVerified: {
                  equals: true,
                },
              })}
              isRequired
              source="operators"
              reference="User"
              label="Operators"
              parse={(value) => {
                if (Array.isArray(value)) {
                  return value.map((id) => ({ id }));
                }
                return { set: [] };
              }}
              format={(value) => {
                if (Array.isArray(value)) {
                  return value.map((item) => item?.id || item);
                }
                if (value?.set) {
                  return value.set.map((item) => item?.id || item);
                }
                return [];
              }}
            >
              <AutocompleteArrayInput
                suggestionLimit={5}
                optionText={UserTitle}
              />
            </ReferenceArrayInput>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <SourceInput />
          </Grid>
        </Grid>
      </div>

      <div className={getStepClass(1)}>
        <OperationFlagsForm />
      </div>

      <div className={getStepClass(2)}>
        <AccountingFlagForm isEmployee={isEmployee} />
        <PaymentInformationInput />
      </div>

      <div className={getStepClass(3)}>
        <CommentForm />
      </div>
    </>
  );

  function renderEmployeeSettings() {
    return (
      <Grid container spacing={2}>
        <Grid item xs={12} md={6} lg={6}>
          <NumberInput
            step={1}
            max={999999999}
            validate={maxLength(9)}
            parse={(value: string) => {
              if (value.toString().length > 9) {
                value = value.toString().slice(0, 9);
              }
              return Number(value);
            }}
            helperText={false}
            label="Min. amount B2C"
            source="minimumAmountB2C"
          />
        </Grid>
        <Grid item xs={12} md={6} lg={6}>
          <NumberInput
            step={1}
            max={999999999}
            validate={maxLength(9)}
            parse={(value: string) => {
              if (value.toString().length > 9) {
                value = value.toString().slice(0, 9);
              }
              return Number(value);
            }}
            helperText={false}
            label="Min. amount B2B"
            source="minimumAmountB2B"
          />
        </Grid>
        <Grid item xs={12} md={6} lg={6}>
          <NumberInput
            step={1}
            max={999999999}
            validate={maxLength(9)}
            parse={(value: string) => {
              if (value.toString().length > 9) {
                value = value.toString().slice(0, 9);
              }
              return Number(value);
            }}
            helperText={false}
            label="Max. amount B2C"
            source="maximumAmountB2C"
          />
        </Grid>
        <Grid item xs={12} md={6} lg={6}>
          <NumberInput
            step={1}
            max={999999999}
            validate={maxLength(9)}
            parse={(value: string) => {
              if (value.toString().length > 9) {
                value = value.toString().slice(0, 9);
              }
              return Number(value);
            }}
            helperText={false}
            label="Max. amount B2B"
            source="maximumAmountB2B"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <BooleanInput
            step={1}
            label="Legal Knowledge"
            source="hasLegalKnowledge"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <BooleanInput
            step={1}
            label="Language Knowledge"
            source="hasLanguageKnowledge"
          />
        </Grid>
      </Grid>
    );
  }
};

export default UserEditForm;
