import BasicInfoForm from "./steps/BasicInfoForm";

interface AdditionalContactFormProps {
  isLaywerUser: boolean;
  isInsolvencyAdministrator: boolean;
  onValidationChange: (isValid: boolean) => void;
}

const AdditionalContactForm = ({
  isLaywerUser,
  isInsolvencyAdministrator,
  onValidationChange,
}: AdditionalContactFormProps) => {
  return (
    <>
      <BasicInfoForm
        isAdditionalContact={true}
        isLaywerUser={isLaywerUser}
        isInsolvencyAdministrator={isInsolvencyAdministrator}
        onValidationChange={onValidationChange}
      />
    </>
  );
};

export default AdditionalContactForm;
