import { Badge, Button } from "@material-ui/core";
import * as icons from "@material-ui/icons";
import React, { Suspense, useState } from "react";
import { LoadingIndicator, Tab, useGetIdentity } from "react-admin";
import { VIP_USERS } from "../../../config/AppConfig";
import { useAircall } from "../../../context/hook/useAircall";
import { useClaim } from "../../../context/hook/useClaim";
import { theme } from "../../../theme/theme";
import { ClaimDrawer } from "../../ClaimDrawer";
import { TabbedShowLayoutStyled } from "./TabbedShowLayoutStyled";
import { ActivitiesTab } from "./tabs/ActivitiesTab";

const GeneralTab = React.lazy(() => import("./tabs/GeneralTab"));
const PaymentsTab = React.lazy(() => import("./tabs/PaymentsTab"));
const CommentTab = React.lazy(() => import("./tabs/CommentTab"));
const FilesTab = React.lazy(() => import("./tabs/FilesTab"));

export const ClaimTabs = React.memo((props: any) => {
  const {
    AppsOutlined,
    PaymentOutlined,
    ChatOutlined,
    FileCopyOutlined,
    CommentOutlined,
    CallOutlined,
  } = icons;

  const [showActivityAdd, setShowActivityAdd] = useState(false);
  const [activityPlaceholderText, setActivityPlaceholderText] = useState("");
  const { identity } = useGetIdentity();

  const { record, absoluteTotal, debtorData, creditorData } = useClaim(
    props.claimId
  );
  const { hidePhone, handleCallDebtor } = useAircall(record.id);

  return (
    <>
      <TabbedShowLayoutStyled>
        <Tab label="General" icon={<AppsOutlined />}>
          <Suspense fallback={<LoadingIndicator />}>
            <GeneralTab claimId={props?.claimId} />
          </Suspense>
        </Tab>
        <Tab label="Payments" icon={<PaymentOutlined />}>
          <Suspense fallback={<LoadingIndicator />}>
            <PaymentsTab absoluteTotal={absoluteTotal} />
          </Suspense>
        </Tab>
        <Tab label="Activities" icon={<ChatOutlined />}>
          <Suspense fallback={<LoadingIndicator />}>
            <ActivitiesTab
              showActivityAdd={showActivityAdd}
              setShowActivityAdd={setShowActivityAdd}
              activityPlaceholderText={activityPlaceholderText}
              setActivityPlaceholderText={setActivityPlaceholderText}
              creditor={creditorData}
              debtor={debtorData}
            />
          </Suspense>
        </Tab>
        {(identity?.username?.includes("debtist.de") ||
          identity?.username === "admin") && (
          <Tab label="Files" icon={<FileCopyOutlined />}>
            <Suspense fallback={<LoadingIndicator />}>
              <FilesTab />
            </Suspense>
          </Tab>
        )}
        <Tab
          label="Comment"
          icon={
            <Badge
              invisible={!record?.comment?.length}
              badgeContent={<></>}
              color={"primary"}
              variant="dot"
              style={{ marginTop: 4 }}
            >
              <CommentOutlined />
            </Badge>
          }
          style={{
            background: record?.comment?.length ? "var(--primary)" : "",
            color: record?.comment?.length ? "#fff" : "",
          }}
        >
          <Suspense fallback={<LoadingIndicator />}>
            <CommentTab />
          </Suspense>
        </Tab>
        {!hidePhone && (
          <Button
            hidden={hidePhone}
            title="Call Debtor"
            onClick={handleCallDebtor}
            style={{
              position: "absolute",
              right: 25,
              top: "50%",
              padding: 8,
              transform: "translateY(-50%)",
            }}
            size="small"
          >
            <>
              <CallOutlined />
              {(!debtorData.phoneVerificationStatus ||
                debtorData.phoneVerificationStatus === "UNKNOWN") && (
                <icons.Help
                  style={{
                    height: 14,
                    color: theme.palette.warning.dark,
                  }}
                />
              )}
              {debtorData.phoneVerificationStatus === "VALID" && (
                <icons.VerifiedUser
                  style={{
                    height: 14,
                    color: theme.palette.success.main,
                  }}
                />
              )}
              {debtorData.phoneVerificationStatus === "INVALID" && (
                <icons.RemoveCircle
                  style={{
                    height: 14,
                    color: theme.palette.error.main,
                  }}
                />
              )}
            </>
          </Button>
        )}
      </TabbedShowLayoutStyled>

      {VIP_USERS.includes(record?.identity?.username) && <ClaimDrawer />}
    </>
  );
});
