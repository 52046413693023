import * as React from "react";

import {
  AutocompleteInput,
  Create,
  CreateProps,
  DateInput,
  NumberInput,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextInput,
} from "react-admin";
import { useFormState } from "react-final-form";
import { CLAIM_TYPES } from "../api/claim/Claim";
import { theme } from "../theme/theme";
import { UserTitle } from "../user/UserTitle";

const OriginalAmountDueInput = () => {
  const { values } = useFormState();

  return (
    <NumberInput
      required
      label="Original Amount Due (Gross)"
      source="originalAmountDue"
      onBlur={() => {
        if (!values.totalPending) {
          values.totalPending = values.originalAmountDue - values.totalPaid;
        }
      }}
    />
  );
};

const TotalPendingInput = () => {
  const { values } = useFormState();

  return (
    <NumberInput
      required
      label="Total Pending"
      source="totalPending"
      onBlur={() => {
        if (!values.originalAmountDue) {
          values.originalAmountDue = values.totalPending;
        }
      }}
    />
  );
};

const TotalPaidInput = () => {
  const { values } = useFormState();

  return (
    <NumberInput
      required
      label="Total Paid"
      defaultValue={0}
      source="totalPaid"
      onBlur={() => {
        if (
          !values.totalPending ||
          values.totalPending === values.originalAmountDue
        ) {
          values.totalPending = values.originalAmountDue - values.totalPaid;
        }
      }}
    />
  );
};

export const ClaimCreate = (props: CreateProps): React.ReactElement => {
  return (
    <Create {...props}>
      <SimpleForm>
        <ReferenceInput
          filterToQuery={(searchText) => ({
            name: { startsWith: searchText },
          })}
          isRequired
          source="creditor.id"
          reference="User"
          label="Creditor"
        >
          <AutocompleteInput suggestionLimit={5} optionText={UserTitle} />
        </ReferenceInput>
        <ReferenceInput
          filterToQuery={(searchText) => ({
            name: { startsWith: searchText },
          })}
          source="holder.id"
          reference="User"
          label="Holder (Vertreten durch)"
        >
          <AutocompleteInput suggestionLimit={5} optionText={UserTitle} />
        </ReferenceInput>
        <ReferenceInput
          filterToQuery={(searchText) => ({
            name: { startsWith: searchText },
          })}
          isRequired
          source="debtor.id"
          reference="User"
          label="Debtor"
        >
          <AutocompleteInput suggestionLimit={5} optionText={UserTitle} />
        </ReferenceInput>
        <ReferenceInput
          filterToQuery={(searchText) => ({
            name: { startsWith: searchText },
          })}
          source="manager.id"
          reference="User"
          label="Manager"
        >
          <AutocompleteInput suggestionLimit={5} optionText={UserTitle} />
        </ReferenceInput>
        <DateInput
          required
          label="Invoice Created At"
          source="invoiceCreatedAt"
        />
        <DateInput required label="Due Date" source="dueDate" />
        <SelectInput
          required
          source="status"
          label="Status"
          choices={getFormattedClaimStatusChoices()}
          defaultValue={"Open"}
          optionText="label"
          optionValue="value"
        />
        <SelectInput
          source="stage"
          label="Stage"
          disabled
          choices={[
            { label: "Reminder", value: "Reminder" },
            { label: "Precourt", value: "Precourt" },
            { label: "Court", value: "Court" },
            { label: "Monitoring", value: "Monitoring" },
          ]}
          optionText="label"
          optionValue="value"
        />
        <TextInput
          label="Title (External ID)"
          source="title"
          placeholder="Example: 0001"
        />
        <SelectInput
          source="currency"
          label="Currency"
          choices={[
            { label: "EUR", value: "EUR" },
            { label: "USD", value: "USD" },
            { label: "GBP", value: "GBP" },
            { label: "CHF", value: "CHF" },
            { label: "PLN", value: "PLN" },
            { label: "DKK", value: "DKK" },
            { label: "NOK", value: "NOK" },
            { label: "SEK", value: "SEK" },
            { label: "JPY", value: "JPY" },
            { label: "AUD", value: "AUD" },
            { label: "CAD", value: "CAD" },
            { label: "MXN", value: "SEK" },
          ]}
          optionText="label"
          allowEmpty
          optionValue="value"
        />
        <OriginalAmountDueInput />
        <TotalPaidInput />
        <TotalPendingInput />
        <NumberInput required label="Tax Rate" source="taxRate" />
        <NumberInput label="Interest Rate" source="interestRate" />
        <NumberInput
          label="Auto Settlement in Percent"
          source="autoSettlementInPercent"
        />
        <DateInput
          required
          label="Auto Settlement Expiry"
          source="autoSettlementExpiry"
        />
        <SelectInput
          source="claimType"
          label="Claim Type"
          choices={CLAIM_TYPES.map((claim) => ({
            label: claim.title + " (" + claim.number + ")",
            value: claim.key,
          }))}
          optionText="label"
          allowEmpty
          optionValue="value"
        />
        <TextInput
          label="Comment"
          multiline
          minRows={3}
          fullWidth
          source="comment"
        />
      </SimpleForm>
    </Create>
  );
};

export function getFormattedClaimStatusChoices(): object[] {
  return [
    {
      value: "active-header",
      label: <span style={{ fontWeight: 600, fontSize: 11 }}>{"Aktiv"}</span>,
      disabled: true,
    },
    {
      label: (
        <span
          style={{
            fontSize: 13,
            backgroundColor: theme.palette.info.main,
            color: "white",
            width: "100%",
            fontWeight: 600,
            padding: "8px 12px",
            borderRadius: "8px",
          }}
        >
          ReminderLevel
        </span>
      ),
      value: "ReminderLevel",
    },
    {
      label: (
        <span
          style={{
            fontSize: 13,
            backgroundColor: theme.palette.secondary.main,
            color: "white",
            width: "100%",
            fontWeight: 600,
            padding: "8px 12px",
            borderRadius: "8px",
          }}
        >
          EncashmentLevel
        </span>
      ),
      value: "EncashmentLevel",
    },
    {
      label: (
        <span
          style={{
            fontSize: 13,
            backgroundColor: theme.palette.error.dark,
            color: "white",
            width: "100%",
            fontWeight: 600,
            padding: "8px 12px",
            borderRadius: "8px",
          }}
        >
          JudicialLevel
        </span>
      ),
      value: "JudicialLevel",
    },
    {
      value: "inactive-header",
      label: <span style={{ fontWeight: 600, fontSize: 11 }}>{"Inaktiv"}</span>,
      disabled: true,
    },
    {
      label: (
        <span
          style={{
            fontSize: 13,
            backgroundColor: theme.palette.grey[800],
            color: "white",
            width: "100%",
            fontWeight: 600,
            padding: "8px 12px",
            borderRadius: "8px",
          }}
        >
          Paused
        </span>
      ),
      value: "Paused",
    },
    {
      label: (
        <span
          style={{
            fontSize: 13,
            backgroundColor: theme.palette.primary.main,
            color: "white",
            width: "100%",
            fontWeight: 600,
            padding: "8px 12px",
            borderRadius: "8px",
          }}
        >
          Open
        </span>
      ),
      value: "Open",
    },
    {
      value: "done-header",
      label: (
        <span style={{ fontWeight: 600, fontSize: 11 }}>{"Erledigt"}</span>
      ),
      disabled: true,
    },
    {
      label: (
        <span
          style={{
            fontSize: 13,
            backgroundColor: theme.palette.success.dark,
            color: "white",
            width: "100%",
            fontWeight: 600,
            padding: "8px 12px",
            borderRadius: "8px",
          }}
        >
          Paid
        </span>
      ),
      value: "Paid",
    },
    {
      label: (
        <span
          style={{
            fontSize: 13,
            backgroundColor: theme.palette.error.main,
            color: "white",
            width: "100%",
            fontWeight: 600,
            padding: "8px 12px",
            borderRadius: "8px",
          }}
        >
          Cancelled
        </span>
      ),
      value: "Cancelled",
    },
    {
      label: (
        <span
          style={{
            fontSize: 13,
            backgroundColor: theme.palette.error.main,
            color: "white",
            width: "100%",
            fontWeight: 600,
            padding: "8px 12px",
            borderRadius: "8px",
          }}
        >
          Closed
        </span>
      ),
      value: "Closed",
    },
  ];
}
