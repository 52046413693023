import {
  Card,
  CardContent,
  Divider,
  FormControlLabel,
  Grid,
  Switch,
  Tab,
  Tabs,
  Typography,
} from "@material-ui/core";

import React, { useCallback, useEffect, useRef, useState } from "react";
import { SelectInput, SimpleForm, Title, useGetIdentity } from "react-admin";
import ReactSimplyCarousel from "react-simply-carousel";

import EventNoteIcon from "@material-ui/icons/EventNote";
import ExploreIcon from "@material-ui/icons/Explore";
import FormatListBulletedIcon from "@material-ui/icons/FormatListBulleted";
import GavelIcon from "@material-ui/icons/Gavel";
import HelpOutlinedIcon from "@material-ui/icons/HelpOutlined";
import MoneyOffIcon from "@material-ui/icons/MoneyOff";

import { Timelapse } from "@material-ui/icons";
import AircallPhoneComponent from "../Components/aircall/AirCallPhone";
import { Automations } from "../Components/cards/AutomationsCard";
import AssignedClaimsTab from "../Components/tabs/dashboard/AssignedClaimsTab";
import ClaimsToDoTab from "../Components/tabs/dashboard/ClaimsToDoTab";
import InsolvencyActivityToDoTab from "../Components/tabs/dashboard/InsolvencyActivityToDoTab";
import JudicialActivityToDoTab from "../Components/tabs/dashboard/JudicialActivityToDoTab";
import OtherToDoTab from "../Components/tabs/dashboard/OtherToDoTab";
import PlannedCallsToDoTab from "../Components/tabs/dashboard/PlannedCallsToDoTab";
import ResearchToDoTab from "../Components/tabs/dashboard/ResearchToDoTab";
import WaitingForCreditorToDoTab from "../Components/tabs/dashboard/WaitingForCreditorToDoTab";
import { usePhone } from "../context/PhoneContext";
import { useManagers } from "../context/hook/useManagers";

const today: Date = new Date();
today.setHours(23, 59, 59, 59);

const TIME_ZONES: string[] = [
  "Europe/Berlin",
  "America/New_York",
  "America/Los_Angeles",
  "Europe/London",
  "Asia/Tokyo",
  "Asia/Shanghai",
  "Asia/Hong_Kong",
  "Africa/Johannesburg",
  "America/Bogota",
];

const Dashboard = React.memo(() => {
  const { showAirCallPhone } = usePhone();
  const [callsLoaded, setCallsLoaded] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingAssignedClaims, setIsLoadingAssignedClaims] = useState(false);
  const [personalization, setPersonalization] = useState(true);
  const [enableLegal, setEnableLegal] = useState(true);
  const [tabValue, setTabValue] = React.useState(0);
  const [parentTabValue, setParentTabValue] = React.useState(0);
  const [totalPlannedCalls, setTotalPlannedCalls] = useState(0);
  const [totalWaiting, setTotalWaiting] = useState("0");
  const [totalOther, setTotalOther] = useState(0);
  const [totalResearch, setTotalResearch] = useState(0);
  const [totalJudicialActivity, setTotalJudicialActivity] = useState(0);
  const [totalInsolvencyActivity, setTotalInsolvencyActivity] = useState(0);
  const [totalWaitingForCreditor, setTotalWaitingForCreditor] = useState(0);
  const [loadedAssignedClaims, setLoadedAssignedClaims] = useState([]);
  const [timeOfDayGreeting, setTimeOfDayGreeting] = useState<
    "Good morning" | "Good afternoon" | "Good evening"
  >("Good morning");
  const [activeSlideIndex, setActiveSlideIndex] = useState(0);
  const [countFlagClicks, setCountFlagClicks] = useState(0);
  const [nextFlight, setNextFlight] = useState(false);
  const [loadedUsers, setLoadedUsers] = useState([]);
  const [selectedManager, setSelectedManager] = useState("");

  const [userCheckedAssignedClaims, setUserCheckedAssignedClaims] =
    useState(false);

  const [isScrolledPastMainTabs, setIsScrolledPastMainTabs] = useState(false);
  const mainTabsRef = useRef<HTMLDivElement>(null);

  const handleChange = useCallback(
    (_event: React.SyntheticEvent, newValue: number) => {
      setTabValue(newValue);
    },
    [setTabValue]
  );

  const handleParentTabChange = useCallback(
    (_event: React.SyntheticEvent, newValue: number) => {
      setParentTabValue(newValue);
    },
    [setParentTabValue]
  );

  const { identity } = useGetIdentity();
  const { managers } = useManagers();

  useEffect(() => {
    if (managers && Object.entries(managers).length > 0) {
      setLoadedUsers(
        Object.entries(managers).map(([_key, user]) => ({
          id: user.id,
          contactName: user.contactName || "-",
        }))
      );
    }
  }, [managers]);

  const fetchAssignedClaims = useCallback(async () => {
    if (
      !identity?.id ||
      isLoadingAssignedClaims ||
      loadedAssignedClaims?.length
    ) {
      return;
    }

    setIsLoadingAssignedClaims(true);

    try {
      setUserCheckedAssignedClaims(false);

      const response = await fetch(
        `${process.env.REACT_APP_SERVER_URL}/api/users/${identity.id}`,
        {
          method: "GET",
          headers: {
            Authorization: localStorage.getItem("credentials") || "",
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error(`Failed to fetch user: ${response.status}`);
      }

      const userData = await response.json();
      if (!userData.claimsAsAssignee) {
        setIsLoadingAssignedClaims(false);
      }
      setLoadedAssignedClaims(userData.claimsAsAssignee || []);
    } catch (error) {
      console.error("Error fetching assigned claims:", error);
    } finally {
      setIsLoadingAssignedClaims(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [identity?.id]);

  useEffect(() => {
    if (loadedAssignedClaims.length === 0 && !isLoading) {
      fetchAssignedClaims();
    }
  }, [fetchAssignedClaims, loadedAssignedClaims.length, isLoading]);

  const updateTimeOfDay: () => void = () => {
    const currentHour = new Date().getHours();
    if (currentHour >= 5 && currentHour < 12) {
      setTimeOfDayGreeting("Good morning");
    } else if (currentHour >= 12 && currentHour < 18) {
      setTimeOfDayGreeting("Good afternoon");
    } else {
      setTimeOfDayGreeting("Good evening");
    }
  };

  useEffect(() => {
    updateTimeOfDay(); // Initial update
    const intervalId = setInterval(updateTimeOfDay, 60000);

    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    if (countFlagClicks === 3) {
      setNextFlight(true);
      setCountFlagClicks(0);

      setTimeout(() => {
        setNextFlight(false);
      }, 2000);
    }
  }, [countFlagClicks, setCountFlagClicks, setNextFlight]);

  useEffect(() => {
    const handleScroll = () => {
      if (mainTabsRef.current) {
        const mainTabsOffsetTop = mainTabsRef.current.offsetTop;
        const scrollPosition = window.scrollY;
        if (scrollPosition > mainTabsOffsetTop) {
          setIsScrolledPastMainTabs(true);
        } else {
          setIsScrolledPastMainTabs(false);
        }
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isScrolledPastMainTabs]);

  return (
    <div>
      <Title title="Dashboard" />
      <Grid container spacing={2} alignItems={"flex-start"}>
        <Grid xs={12} md={6} item>
          <Tabs
            onChange={handleParentTabChange}
            value={parentTabValue}
            aria-label={"dashboard main tabs"}
            variant={"scrollable"}
            scrollButtons={"auto"}
          >
            <Tab
              label={`${
                identity?.fullName?.includes(" ")
                  ? identity?.fullName?.split(" ")[0] + "'s "
                  : identity?.fullName + "'s "
              } Dashboard`}
            />
            <Tab
              className={
                loadedAssignedClaims.length > 0 && !userCheckedAssignedClaims
                  ? "you-got-mail"
                  : ""
              }
              label={`Meine Akten (${loadedAssignedClaims.length})`}
              disabled={loadedAssignedClaims.length < 1}
              onKeyPress={() => setUserCheckedAssignedClaims(true)}
            />
          </Tabs>
        </Grid>

        <Grid xs={12} md={6} item className="personalized">
          <SimpleForm
            style={{
              width: 220,
              display: "inline-block",
              verticalAlign: "middle",
            }}
            toolbar={false}
            onSubmit={() => undefined}
          >
            <SelectInput
              source="manager"
              alwaysOn
              label="Switch Agent"
              value={selectedManager}
              defaultValue={selectedManager}
              onChange={(e) => {
                setSelectedManager(e.target.value);
              }}
              choices={[
                ...[{ label: "(none)", value: null }],
                ...loadedUsers.map((user) => ({
                  label: user.contactName,
                  value: user.id,
                })),
              ]}
              optionText="label"
              optionValue="value"
            />
          </SimpleForm>
          <FormControlLabel
            label="Legal Mode"
            value={enableLegal}
            onChange={(_e, checked) => {
              setEnableLegal(checked);
            }}
            disabled={isLoading}
            control={<Switch color="secondary" defaultChecked={enableLegal} />}
            labelPlacement={"start"}
          />
          <FormControlLabel
            label="Personalized"
            value={personalization}
            onChange={(_e, checked) => {
              setPersonalization(checked);
            }}
            disabled={isLoading}
            control={
              <Switch color="secondary" defaultChecked={personalization} />
            }
            labelPlacement={"start"}
          />
        </Grid>
      </Grid>

      <Grid
        container
        spacing={2}
        alignItems={"flex-start"}
        style={{
          marginTop: 10,
        }}
      >
        <Grid xs={12} md={3} item>
          <Card variant="outlined" title="Time Zones">
            <CardContent
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor:
                  timeOfDayGreeting === "Good evening" ? "#2f2e40" : "#fff",
                minHeight: 40,
              }}
            >
              <Typography
                variant="h6"
                style={{
                  color:
                    timeOfDayGreeting === "Good evening"
                      ? "#fff"
                      : "rgba(0, 0, 0, 0.87)",
                }}
              >{`${timeOfDayGreeting} ${
                identity?.fullName?.includes(" ")
                  ? identity?.fullName?.split(" ")[0]
                  : identity?.fullName
              } ${
                timeOfDayGreeting === "Good evening" ? "🌙" : "🌞"
              }`}</Typography>
            </CardContent>
            <Divider />
          </Card>
        </Grid>

        <Grid xs={12} md={3} item>
          <Card variant="outlined" style={{ minHeight: "100%" }}>
            <CardContent
              className="flex-center relative lessPadding"
              onClick={() => setCountFlagClicks(countFlagClicks + 1)}
            >
              <ReactSimplyCarousel
                activeSlideIndex={activeSlideIndex}
                itemsToScroll={1}
                itemsToShow={1}
                onRequestChange={setActiveSlideIndex}
                forwardBtnProps={{
                  style: {
                    display: "none",
                  },
                }}
                backwardBtnProps={{
                  style: {
                    display: "none",
                  },
                }}
                containerProps={{
                  style: {
                    width: "100%",
                    justifyContent: "center",
                    alignItems: "center",
                    userSelect: "none",
                  },
                }}
                autoplayDelay={5000}
                speed={300}
                autoplay
              >
                {TIME_ZONES.map((tz) => (
                  <div
                    id={tz}
                    className="tab-col flex-center"
                    style={{
                      minWidth: 200,
                    }}
                  >
                    <Typography variant="subtitle2" className="flex-center">
                      {`${tz.split("/")[1].replace("_", " ")} ${
                        tz.includes("Berlin")
                          ? "🇩🇪"
                          : tz.includes("Bogota")
                          ? "🇨🇴"
                          : tz.includes("America")
                          ? "🇺🇸"
                          : tz.includes("London")
                          ? "🇬🇧"
                          : tz.includes("Tokyo")
                          ? "🇯🇵"
                          : tz.includes("Johannesburg")
                          ? "🇿🇦"
                          : tz.includes("Shanghai") || tz.includes("Hong_Kong")
                          ? "🇨🇳"
                          : ""
                      }`}
                    </Typography>
                    <Typography
                      variant="subtitle2"
                      className="flex-center"
                      style={{
                        fontWeight: "bold",
                        color: "var(--primary)",
                      }}
                    >
                      {`${new Intl.DateTimeFormat("de-DE", {
                        timeZone: tz,
                        hour: "2-digit",
                        minute: "2-digit",
                      }).format(new Date())}`}
                    </Typography>
                  </div>
                ))}
              </ReactSimplyCarousel>

              {nextFlight && (
                <div className="next-flight" style={{ height: 20 }}>
                  {"🛩️"}
                </div>
              )}
            </CardContent>
          </Card>
        </Grid>
        <Grid xs={12} md={3} item>
          <div
            style={{
              position:
                isScrolledPastMainTabs && showAirCallPhone
                  ? "fixed"
                  : "relative",
              zIndex: isScrolledPastMainTabs && showAirCallPhone ? 9999 : 1,
              right: isScrolledPastMainTabs && showAirCallPhone ? 170 : 0,
              top: isScrolledPastMainTabs && showAirCallPhone ? 60 : 0,
            }}
          >
            <AircallPhoneComponent
              hideStats
              loadedAssignedClaims={loadedAssignedClaims}
              totalPlannedCalls={totalPlannedCalls}
            />
          </div>
        </Grid>
        <Grid xs={12} md={3} item>
          <Automations />
        </Grid>
      </Grid>

      <div
        role="tabpanel"
        hidden={parentTabValue !== 0}
        id={`parent-tabpanel-${0}`}
        ref={mainTabsRef}
      >
        <Tabs
          onChange={handleChange}
          value={tabValue}
          aria-label={"dashboard tabs"}
          style={{
            marginTop: 25,
            marginBottom: 15,
            background: "rgba(255, 255, 255, 0.5)",
            borderWidth: 1,
            borderStyle: "solid",
            borderColor: "rgba(0, 0, 0, 0.12)",
            borderRadius: 8,
            maxWidth: "100%",
          }}
          variant={"scrollable"}
          scrollButtons={"auto"}
        >
          <Tab
            label={
              "Calls" +
              (callsLoaded === true && tabValue === 0 && totalPlannedCalls > 0
                ? ` (${totalPlannedCalls})`
                : "")
            }
            icon={<EventNoteIcon />}
          />
          <Tab
            label={
              "Waiting" +
              (tabValue === 1 && Number(totalWaiting.replaceAll("+", "")) > 0
                ? ` (${totalWaiting})`
                : "")
            }
            icon={<FormatListBulletedIcon />}
          />
          <Tab
            label={
              `Other` +
              (tabValue === 2 && totalOther > 0 ? ` (${totalOther})` : "")
            }
            icon={<HelpOutlinedIcon />}
          />
          <Tab
            label={
              `Research` +
              (tabValue === 3 && totalResearch > 0 ? ` (${totalResearch})` : "")
            }
            icon={<ExploreIcon />}
          />
          <Tab
            label={
              `Judicial activity` +
              (tabValue === 4 && totalJudicialActivity > 0
                ? ` (${totalJudicialActivity})`
                : "")
            }
            icon={<GavelIcon />}
          />
          <Tab
            label={
              `Insolvency activity` +
              (tabValue === 5 && totalInsolvencyActivity > 0
                ? ` (${totalInsolvencyActivity})`
                : "")
            }
            icon={<MoneyOffIcon />}
          />
          <Tab
            label={
              `Waiting for Creditor` +
              (tabValue === 6 && totalWaitingForCreditor > 0
                ? ` (${totalWaitingForCreditor})`
                : "")
            }
            icon={<Timelapse />}
          />
        </Tabs>

        <PlannedCallsToDoTab
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          personalization={personalization}
          selectedManager={selectedManager}
          enableLegal={enableLegal}
          setCallsLoaded={setCallsLoaded}
          setTotalPlannedCalls={setTotalPlannedCalls}
          tabValue={tabValue}
        />

        <ClaimsToDoTab
          personalization={personalization}
          selectedManager={selectedManager}
          enableLegal={enableLegal}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          totalWaiting={totalWaiting}
          setTotalWaiting={setTotalWaiting}
          tabValue={tabValue}
        />

        <OtherToDoTab
          personalization={personalization}
          selectedManager={selectedManager}
          enableLegal={enableLegal}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          setTotalOther={setTotalOther}
          tabValue={tabValue}
        />

        <ResearchToDoTab
          personalization={personalization}
          selectedManager={selectedManager}
          enableLegal={enableLegal}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          setTotalResearch={setTotalResearch}
          tabValue={tabValue}
        />

        <JudicialActivityToDoTab
          personalization={personalization}
          selectedManager={selectedManager}
          enableLegal={enableLegal}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          setTotalJudicialActivity={setTotalJudicialActivity}
          tabValue={tabValue}
        />

        <InsolvencyActivityToDoTab
          personalization={personalization}
          selectedManager={selectedManager}
          enableLegal={enableLegal}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          setTotalInsolvencyActivity={setTotalInsolvencyActivity}
          tabValue={tabValue}
        />

        <WaitingForCreditorToDoTab
          personalization={personalization}
          selectedManager={selectedManager}
          enableLegal={enableLegal}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          setTotalWaitingForCreditor={setTotalWaitingForCreditor}
          tabValue={tabValue}
        />
      </div>

      <AssignedClaimsTab isLoading={isLoading} tabValue={parentTabValue} />
    </div>
  );
});

export default Dashboard;
