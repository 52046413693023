import {
  Button,
  Card,
  CardContent,
  Divider,
  Typography,
} from "@material-ui/core";
import {
  PhoneEnabled,
  PhoneInTalkOutlined,
  PhoneLockedOutlined,
} from "@material-ui/icons";
import React from "react";
import { usePhone } from "../../context/PhoneContext";

interface AirCallPhoneProps {
  hideStats?: boolean;
  killPadding?: boolean;
  totalPlannedCalls?: any;
  loadedAssignedClaims?: any;
}

const AircallPhoneComponent: React.FC<AirCallPhoneProps> = ({
  totalPlannedCalls,
  loadedAssignedClaims,
}) => {
  const {
    showAirCallPhone,
    handleCheckLogin,
    isLoggedIn,
    isPhoneReady,
    statusMessage,
    airCallTab,
    setAirCallTab,
  } = usePhone();

  const handlePhoneLogin = () => {
    if (airCallTab && !airCallTab.closed) {
      // The tab is open, switch to it
      airCallTab.focus();
    } else {
      // The tab is closed or hasn't been opened, open it
      setAirCallTab(window.open("https://phone.aircall.io/", "_blank"));
    }
  };

  return (
    <Card variant="outlined" onClick={!isLoggedIn ? handleCheckLogin : null}>
      <CardContent
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          minHeight: 40,
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "center",
          }}
        >
          <Typography variant="subtitle2" className="flex-center">
            {totalPlannedCalls <= 0 || totalPlannedCalls === undefined
              ? "Keine geplanten Calls"
              : `Geplante Calls: ${totalPlannedCalls}`}
          </Typography>
          <Divider
            style={{
              width: "90%",
            }}
          />
          <Typography variant="subtitle2" className="flex-center">
            {loadedAssignedClaims.length === 0
              ? "Keine zugewiesenen Akten"
              : `Zugewiesene Akten: ${loadedAssignedClaims.length}`}
          </Typography>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography
            variant={"body2"}
            style={{
              fontWeight: "bold",
              transition: "cubic-bezier(0.075, 0.82, 0.165, 1)",
              color: isPhoneReady ? "var(--success)" : "var(--warning)",
            }}
          >
            {statusMessage}
          </Typography>
          <Button
            style={{
              marginLeft: "1rem",
              backgroundColor: showAirCallPhone
                ? "var(--error)"
                : "var(--primary)",
              color: "white",
              fontWeight: "bold",
              position: "absolute",
              right: "1rem",
            }}
            variant={"outlined"}
            onClick={handlePhoneLogin}
          >
            <PhoneEnabled />
          </Button>
        </div>

        {isPhoneReady && (
          <Button
            style={{
              marginLeft: "1rem",
              backgroundColor: showAirCallPhone
                ? "var(--error)"
                : "var(--primary)",
              color: "white",
              fontWeight: "bold",
              position: "absolute",
              right: "1rem",
            }}
            variant={"outlined"}
          >
            {showAirCallPhone ? (
              <PhoneLockedOutlined />
            ) : (
              <PhoneInTalkOutlined />
            )}
          </Button>
        )}
      </CardContent>
    </Card>
  );
};

export default AircallPhoneComponent;
