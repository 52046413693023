import * as React from "react";
import { useCallback, useEffect, useState } from "react";

import { colors, Step, StepLabel, Stepper } from "@material-ui/core";
import {
  Button,
  DeleteWithConfirmButton,
  Edit,
  EditProps,
  SaveButton,
  SimpleForm,
  Toolbar,
  useNotify,
} from "react-admin";
import { useHistory } from "react-router-dom";
import UserEditForm from "../Components/form/UserEditForm";

export const UserEdit = (props: EditProps): React.ReactElement => {
  const [activeStep, setActiveStep] = useState<number>(0);

  const handleKeyPress = useCallback(
    (event) => {
      if (event.target === document.body && event.key === "e") {
        window.open("/#/User/" + props.id + "/show", "_self");
      }
    },
    [props.id]
  );

  const steps = [
    { label: "Basic Information" },
    { label: "Operational Preferences" },
    { label: "Accounting Preferences" },
    { label: "Additional Information" },
  ];

  const handleNext = () => {
    setActiveStep((prev) => prev + 1);
  };

  const handlePrev = () => {
    setActiveStep((prev) => prev - 1);
  };

  const notify = useNotify();

  useEffect(() => {
    // attach the event listener
    document.addEventListener("keydown", handleKeyPress);

    // remove the event listener
    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, [handleKeyPress]);
  const history = useHistory();

  return (
    <Edit
      {...props}
      onSuccess={
        !props.options?.inline
          ? () => {
              history.go(-2);
            }
          : () => {
              notify("Success", "info", null, true);
              props.options?.onSuccess();
            }
      }
      mutationMode="pessimistic"
      className="margin-bottom-5"
    >
      <SimpleForm
        toolbar={
          <Toolbar
            className={
              props.options?.onlyShowEmployeePreferences ? "" : "elevated-card"
            }
            style={{
              width: 400,
            }}
          >
            {!props.options?.onlyShowEmployeePreferences && (
              <DeleteWithConfirmButton
                undoable={false}
                mutationMode="pessimistic"
                label="Delete"
                variant="outlined"
                style={{
                  borderColor: colors.red[400],
                  padding: "6px 16px",
                }}
              />
            )}
            {!props.options?.onlyShowEmployeePreferences && activeStep > 0 && (
              <Button onClick={handlePrev} label={"Previous"}></Button>
            )}
            {!props.options?.onlyShowEmployeePreferences &&
              activeStep < steps.length - 1 && (
                <Button onClick={handleNext} label={"Next"}></Button>
              )}
            <SaveButton label="Save" />
          </Toolbar>
        }
      >
        <>
          {!props.options?.onlyShowEmployeePreferences && (
            <Stepper activeStep={activeStep} style={{ width: "95%" }}>
              {steps.map(({ label }) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
          )}
          <UserEditForm
            activeStep={activeStep}
            onlyShowEmployeePreferences={
              props.options?.onlyShowEmployeePreferences
            }
          />
        </>
      </SimpleForm>
    </Edit>
  );
};
