import {
  Divider,
  Grid,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@material-ui/core";
import { Help, RemoveCircle, VerifiedUser } from "@material-ui/icons";
import * as React from "react";
import {
  DateField,
  FunctionField,
  ReferenceField,
  Show,
  ShowProps,
  SimpleShowLayout,
  TextField,
} from "react-admin";
import ReactCountryFlag from "react-country-flag";
import {
  ADDRESS_COUNTRY_READABLE,
  ADDRESS_COUNTRY_SHORT,
} from "../api/address/Address";
import { EnumAddressCountry } from "../api/address/EnumAddressCountry";
import { theme } from "../theme/theme";
import { USER_TITLE_FIELD } from "../user/UserTitle";
import { searchMapByKey } from "../util/MapUtils";

export const AddressShow = (props: ShowProps): React.ReactElement => {
  const handleKeyPress = React.useCallback(
    (event) => {
      if (event.target === document.body && event.key === "e") {
        window.open("/#/Address/" + props.id + "/", "_self");
      }
    },
    [props.id]
  );

  React.useEffect(() => {
    // attach the event listener
    document.addEventListener("keydown", handleKeyPress);

    // remove the event listener
    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, [handleKeyPress]);

  return (
    <Show {...props}>
      <SimpleShowLayout>
        <Grid
          container
          spacing={4}
          className="relativity"
          style={{
            width: "100%",
            paddingTop: "3rem",
          }}
        >
          <FunctionField
            label="Verification Status"
            render={(record: any) => {
              const recordWithDefaults = {
                ...record,
                verificationStatus: record?.verificationStatus ?? "UNKNOWN",
              };

              return (
                <>
                  {(!recordWithDefaults.verificationStatus ||
                    recordWithDefaults.verificationStatus === "UNKNOWN") && (
                    <span
                      className="id-wrapper"
                      style={{
                        background: theme.palette.warning.light,
                        left: 0,
                        maxWidth: 150,
                        display: "flex",
                        alignItems: "center",
                        color: theme.palette.common.white,
                        justifyContent: "space-evenly",
                      }}
                    >
                      <Help />
                      {recordWithDefaults.verificationStatus}
                    </span>
                  )}
                  {recordWithDefaults.verificationStatus === "VALID" && (
                    <span
                      className="id-wrapper"
                      style={{
                        background: theme.palette.success.main,
                        left: 0,
                        maxWidth: 150,
                        display: "flex",
                        alignItems: "center",
                        color: theme.palette.common.white,
                        justifyContent: "space-evenly",
                      }}
                    >
                      <VerifiedUser />
                      {recordWithDefaults.verificationStatus}
                    </span>
                  )}
                  {recordWithDefaults.verificationStatus === "INVALID" && (
                    <span
                      className="id-wrapper"
                      style={{
                        background: theme.palette.error.main,
                        left: 0,
                        maxWidth: 150,
                        display: "flex",
                        alignItems: "center",
                        color: theme.palette.common.white,
                        justifyContent: "space-evenly",
                      }}
                    >
                      <RemoveCircle />
                      {recordWithDefaults.verificationStatus}
                    </span>
                  )}
                </>
              );
            }}
          />
          <div className="id-wrapper -secondary">
            <Typography variant="caption">{"ID: "}</Typography>
            <TextField label="ID" source="id" />
          </div>

          <Grid item xs={12} md={7}>
            <List>
              <ListItem alignItems={"flex-start"}>
                <ListItemText
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                  primary={
                    <Typography variant="caption" style={{ display: "block" }}>
                      {"User"}
                    </Typography>
                  }
                  secondary={
                    <ReferenceField
                      link="show"
                      label="User"
                      source="user.id"
                      reference="User"
                    >
                      <TextField source={USER_TITLE_FIELD} />
                    </ReferenceField>
                  }
                />
              </ListItem>
              <Divider component="li" />
              <ListItem alignItems={"flex-start"}>
                <ListItemText
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                  primary={
                    <Typography variant="caption" style={{ display: "block" }}>
                      {"Address Line 1"}
                    </Typography>
                  }
                  secondary={
                    <TextField label="Address Line 1" source="addressLine1" />
                  }
                ></ListItemText>
              </ListItem>
              <Divider component="li" />
              <ListItem alignItems={"flex-start"}>
                <ListItemText
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                  primary={
                    <Typography variant="caption">
                      {"Address Line 2"}
                    </Typography>
                  }
                  secondary={
                    <TextField label="Address Line 2" source="addressLine2" />
                  }
                ></ListItemText>
              </ListItem>
              <Divider component="li" />

              <ListItem alignItems={"flex-start"}>
                <ListItemText
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                  primary={
                    <Typography variant="caption">{"Postalcode"}</Typography>
                  }
                  secondary={
                    <TextField label="Postalcode" source="postalcode" />
                  }
                ></ListItemText>
              </ListItem>
              <Divider component="li" />

              <ListItem alignItems={"flex-start"}>
                <ListItemText
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                  primary={<Typography variant="caption">{"Town"}</Typography>}
                  secondary={<TextField label="Town" source="town" />}
                ></ListItemText>
              </ListItem>
              <Divider component="li" />

              <ListItem alignItems={"flex-start"}>
                <ListItemText
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                  primary={
                    <Typography variant="caption">{"Country"}</Typography>
                  }
                  secondary={
                    <FunctionField
                      label="Country"
                      render={(record: any) => {
                        const iso2CoutryCode = searchMapByKey(
                          ADDRESS_COUNTRY_SHORT,
                          record?.country as EnumAddressCountry
                        );
                        return (
                          <span>
                            {ADDRESS_COUNTRY_READABLE.get(record?.country)}{" "}
                            {iso2CoutryCode && (
                              <ReactCountryFlag
                                countryCode={iso2CoutryCode}
                                svg
                                style={{ marginLeft: 4, marginRight: 2 }}
                              />
                            )}
                          </span>
                        );
                      }}
                    />
                  }
                ></ListItemText>
              </ListItem>
              <Divider component="li" />

              <ListItem alignItems={"flex-start"}>
                <ListItemText
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                  primary={
                    <Typography variant="caption">{"Language"}</Typography>
                  }
                  secondary={
                    <FunctionField
                      label="Language"
                      render={(record: any) => (
                        <span>
                          {ADDRESS_COUNTRY_READABLE.get(
                            record?.languageCountry
                          )}
                        </span>
                      )}
                    />
                  }
                ></ListItemText>
              </ListItem>
            </List>
          </Grid>

          <Grid item xs={12} md={5}>
            <List>
              <ListItem alignItems={"flex-start"}>
                <ListItemText
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                  primary={
                    <Typography variant="caption">{"Created At"}</Typography>
                  }
                  secondary={
                    <DateField
                      source="createdAt"
                      label="Created At"
                      locales="de-DE"
                      options={{
                        month: "2-digit",
                        day: "2-digit",
                        year: "numeric",
                        hour: "numeric",
                        minute: "numeric",
                        timeZone: "Europe/Berlin",
                      }}
                    />
                  }
                ></ListItemText>
              </ListItem>
              <Divider component="li" />

              <ListItem alignItems={"flex-start"}>
                <ListItemText
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                  primary={
                    <Typography variant="caption">{"Updated At"}</Typography>
                  }
                  secondary={
                    <DateField
                      source="updatedAt"
                      label="Updated At"
                      locales="de-DE"
                      options={{
                        month: "2-digit",
                        day: "2-digit",
                        year: "numeric",
                        hour: "numeric",
                        minute: "numeric",
                        timeZone: "Europe/Berlin",
                      }}
                    />
                  }
                ></ListItemText>
              </ListItem>
              <Divider component="li" />
            </List>
          </Grid>
        </Grid>
      </SimpleShowLayout>
    </Show>
  );
};
