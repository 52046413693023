import * as React from "react";
import { useState } from "react";
import {
  BooleanField,
  Datagrid,
  DateField,
  List,
  ListProps,
  NullableBooleanInput,
  NumberField,
  ReferenceField,
  SelectInput,
  TextField,
} from "react-admin";
import { EnumIntegrationSoftware } from "../api/integration/EnumIntegrationSoftware";
import Pagination from "../Components/Pagination";
import { theme } from "../theme/theme";
import { USER_TITLE_FIELD } from "../user/UserTitle";

export const IntegrationList = (props: ListProps): React.ReactElement => {
  let jsonFilter: any = {
    software: "",
    isActive: "",
  };

  const [softwareFilterValue, setSoftwareFilterValue] = useState(
    jsonFilter.softwareFilter?.toString() || undefined
  );
  const [isActiveFilterValue, setIsActiveFilterValue] = useState(
    jsonFilter.isActive?.toString() || undefined
  );

  const integrationFilters = React.useMemo(
    () => [
      <SelectInput
        source="software.name"
        alwaysOn
        placeholder="Software"
        autoFocus
        fullWidth
        style={{ minWidth: 260 }}
        choices={Object.entries(EnumIntegrationSoftware).map(
          ([key, value]) => ({
            id: key,
            name: value,
          })
        )}
        onChange={(event) => {
          const value = event.target?.value.trim();
          if (value.length > 2) {
            setSoftwareFilterValue(value);
          } else {
            setSoftwareFilterValue(undefined);
          }
        }}
      />,
      <NullableBooleanInput
        source="isActive"
        label="Is Active"
        fullWidth
        style={{ minWidth: 180, minHeight: "40px" }}
        onChange={(event) => {
          if (event.target.value === "true") {
            setIsActiveFilterValue(true);
          } else if (event.target.value === "false") {
            setIsActiveFilterValue(false);
          } else {
            setIsActiveFilterValue(undefined);
          }
        }}
      />,
    ],
    []
  );
  return (
    <List
      {...props}
      bulkActionButtons={false}
      title={"Integrations"}
      perPage={50}
      pagination={<Pagination />}
      sort={{ field: "createdAt", order: "DESC" }}
      className="integration-list"
      filters={integrationFilters}
      filter={{
        software: softwareFilterValue,
        isActive: isActiveFilterValue,
      }}
    >
      <Datagrid rowClick="show" optimized>
        <ReferenceField label="User" source="user.id" reference="User">
          <TextField source={USER_TITLE_FIELD} />
        </ReferenceField>
        <TextField label="Software" source="software" />
        <BooleanField label="Reminders" source="isCustomEmailAddressVerified" />
        <NumberField label="Days to fetch" source="daysOverDueToFetch" />
        <BooleanField label="Is Active" source="isActive" />
        <DateField
          source="lastSyncDate"
          label="Last Sync Date"
          locales="de-DE"
          style={{
            textAlign: "right",
            verticalAlign: "middle",
            color: theme.palette.grey[500],
            fontSize: "0.75rem",
            fontWeight: "bold",
          }}
          options={{
            month: "2-digit",
            day: "2-digit",
            year: "numeric",
            hour: "numeric",
            minute: "numeric",
            timeZone: "Europe/Berlin",
          }}
        />
        <DateField
          source="createdAt"
          label="Created At"
          locales="de-DE"
          style={{
            textAlign: "right",
            verticalAlign: "middle",
            color: theme.palette.grey[500],
            fontSize: "0.75rem",
            fontWeight: "bold",
          }}
          options={{
            month: "2-digit",
            day: "2-digit",
            year: "numeric",
            timeZone: "Europe/Berlin",
          }}
        />
      </Datagrid>
    </List>
  );
};
