import {
  Card,
  CardContent,
  CircularProgress,
  Grid,
  TextField,
  Typography,
} from "@material-ui/core";
import React from "react";
import { Button } from "react-admin";

export const SalesToolsCard: React.MemoExoticComponent<() => JSX.Element> =
  React.memo(() => {
    const [isLoading, setIsLoading] = React.useState(false);
    const [response, setResponse] = React.useState("");
    const [creditorId, setCreditorId] = React.useState("");
    const [titles, setTitles] = React.useState("");

    const cancelClaims = async () => {
      if (!window.confirm("Are you sure you want to cancel the claims?")) {
        return;
      }

      setIsLoading(true);

      const authHeader = {
        headers: { Authorization: localStorage.getItem("credentials") || "" },
      };
      await fetch(
        process.env.REACT_APP_SERVER_URL +
          "/api/claims/tools/execute?bulkClose=true&creditorId=" +
          creditorId +
          "&titles=" +
          titles,
        {
          ...authHeader,
        }
      )
        .then((response) => response.text())
        .then((response) => {
          setResponse(response);
          setIsLoading(false);
        });
    };

    return (
      <>
        <Card variant="outlined">
          <CardContent className="flex-center" style={{ minHeight: 450 }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h6">Mass cancel claims</Typography>
                {isLoading && (
                  <CircularProgress size={16} style={{ marginBottom: 3 }} />
                )}
              </Grid>
              <Grid item xs={12}>
                <Typography variant="subtitle2">Creditor ID</Typography>
                <TextField
                  fullWidth
                  type="text"
                  placeholder="cm5fj0won0009ye3qgp4xul90"
                  value={creditorId}
                  onChange={(e) => setCreditorId(e.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="subtitle2">
                  Claim Titles, separated by ;
                </Typography>
                <TextField
                  fullWidth
                  multiline
                  rows={4}
                  placeholder="RE1234;RE9281;Invoice 911"
                  value={titles}
                  onChange={(e) => setTitles(e.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                {!!response && (
                  <Typography
                    variant="body1"
                    style={{ whiteSpace: "pre-wrap" }}
                  >
                    <pre>{response} Claims cancelled</pre>
                  </Typography>
                )}
              </Grid>
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  size="medium"
                  disabled={isLoading}
                  onClick={cancelClaims}
                  label={"Cancel Claims"}
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </>
    );
  });
